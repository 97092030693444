import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Project } from '@wilson/interfaces';
import { PipesModule } from '@wilson/pipes';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { RoleAction, RolePermissionSubject } from '@wilson/interfaces';
import { AuthorizationModule } from '@wilson/authorization';

@Component({
  selector: 'wilson-set-shift-project',
  templateUrl: './set-shift-project.component.html',
  styleUrls: ['./set-shift-project.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AuthorizationModule,
    CommonModule,
    NzSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NzToolTipModule,
    PipesModule,
    TranslateModule,
  ],
})
export class SetShiftProjectComponent implements OnChanges {
  @Input() disabled = false;
  @Input() projectId = '';
  @Input() projects!: Project[];

  @Output()
  setProjectId = new EventEmitter<string>();

  selectedProject!: Project;

  protected RoleAction = RoleAction;
  protected RolePermissionSubject = RolePermissionSubject;
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['projects']) {
      const sortedProjects = [...changes['projects'].currentValue].sort(
        (a, b) => (a.projectId > b.projectId ? 1 : -1),
      );
      this.projects = sortedProjects;
    }

    if (changes['projectId']?.currentValue) {
      this.selectedProject = this.projects.find(
        ({ id }) => id === this.projectId,
      ) as Project;
    }
  }

  setProject(project: Project | null): void {
    this.setProjectId.emit(project?.id);
  }
}
