export interface ProjectMany {
  data: Project[];
  count: number;
}

export interface Project {
  attachmentUrls: string[];
  clientId: string;
  colorCode: string;
  contactPersonEmail: string;
  contactPersonName: string;
  contactPersonPhone: string;
  description: string;
  endDatetime: string;
  id: string;
  locationId: string;
  managerId: string;
  name: string;
  organizationalUnitId: string;
  projectId: string;
  shortName: string;
  startDatetime: string;
  status: ProjectStatus;
  costCenter: string;
}

export interface ProjectsOptions {
  where?: WhereOption;
  relations?: string[];
  pageNumber?: number;
  limit?: number;
  pageSize?: number;
  orderBy?: {
    startDatetime: 'asc' | 'desc';
  };
}

export interface WhereOption {
  id?: string;
  startDatetime?: string;
  endDatetime?: string;
  status?: string[];
  clientId?: string[];
}

export enum ProjectStatus {
  OPEN = 'open',
  ONGOING = 'ongoing',
  FINISHED = 'finished',
}

export enum InvoiceStatus {
  OPEN = 'open',
  DRAFT = 'draft',
  PAID = 'paid',
  CANCELLED = 'cancelled',
  OVERDUE = 'overdue',
}

export interface ProjectCreatePayload {
  attachmentUrls?: string[];
  clientId?: string | null;
  colorCode?: string | null;
  contactPersonEmail?: string | null;
  contactPersonName?: string | null;
  contactPersonPhone?: string | null;
  description?: string | null;
  endDatetime: Date;
  locationId?: string | null;
  managerId?: string | null;
  name: string;
  projectId: string;
  shortName?: string | null;
  startDatetime: Date;
  status?: ProjectStatus;
  costCenter?: string | null;
}

export interface ProjectUpdatePayload {
  attachmentUrls?: string[];
  clientId?: string | null;
  colorCode?: string | null;
  contactPersonEmail?: string | null;
  contactPersonName?: string | null;
  contactPersonPhone?: string | null;
  description?: string | null;
  endDatetime?: Date;
  locationId?: string | null;
  managerId?: string | null;
  name?: string;
  projectId?: string;
  shortName?: string | null;
  startDatetime?: Date;
  status?: ProjectStatus;
  costCenter?: string | null;
}
