<div class="d-flex flex-row h-100 align-items-center">
  <button
    nz-button
    [nzLoading]="icon.isLoading"
    [nzType]="buttonType"
    nz-tooltip
    [nzTooltipTitle]="
      !hasPermission ? ('general.forbidden_action' | translate) : ''
    "
    [disabled]="!hasPermission"
    nzShape="circle"
    data-test-id="wilson-ag-grid-renderer:icon-action-button"
    class="me-1"
    [ngClass]="{
      'me-1': true,
      hidden: icon.shouldDisplay && !icon.shouldDisplay(data)
    }"
    [nzDanger]="icon.nzDanger"
    (click)="onClick(icon)"
    *ngFor="let icon of icons"
    #buttonList
  >
    <fa-icon
      *ngIf="!icon.isLoading"
      [size]="icon.iconSize ?? '1x'"
      [icon]="icon.faIcon"
    ></fa-icon>
  </button>
</div>
