import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule as Forms,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  EndDateTimeValidator,
  FormsModule,
  StartDateTimeValidator,
  WhiteSpaceValidator,
} from '@wilson/forms';
import {
  DateRange,
  GeoLocation,
  Sector,
  StayAttachment,
  StayStatus,
  StayType,
  TimelineStayManagementDrawerFormFieldEnum,
  TimelineStayManagementDrawerFormValue,
  TimelineStaysDrawerFormControls,
} from '@wilson/interfaces';
import { LocationsStoreService } from '@wilson/locations';
import { UsersSelectComponent } from '@wilson/non-domain-specific/account/account-helpers';
import {
  LocationChangedEvent,
  LocationSelectsComponent,
} from '@wilson/non-domain-specific/activities-helpers/components';
import { PipesModule } from '@wilson/pipes';
import { DateTimeFormat } from '@wilson/utils';
import { isAfter, isEqual, set } from 'date-fns';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzSegmentedModule } from 'ng-zorro-antd/segmented';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import {
  Observable,
  Subscription,
  combineLatest,
  debounceTime,
  filter,
  startWith,
} from 'rxjs';
import { AccomodationPrefillComponent } from '../accomodation-prefill/accomodation-prefill.component';
import { FindHotelComponent } from '../find-hotel/find-hotel.component';
import { NecessaryStayToggleComponent } from '../necessary-stay-toggle/necessary-stay-toggle.component';
import { StayAttachmentsComponent } from '../stay-attachments/stay-attachments.component';
import { StaysStatusComponent } from '../stays-status/stays-status.component';

export type TimelineStaysDrawerForm =
  FormGroup<TimelineStaysDrawerFormControls>;

@Component({
  selector: 'wilson-timeline-stays-drawer-form',
  standalone: true,
  imports: [
    CommonModule,
    Forms,
    ReactiveFormsModule,
    TranslateModule,
    NzSwitchModule,
    NzFormModule,
    NzSelectModule,
    FormsModule,
    PipesModule,
    NzTimePickerModule,
    NzInputNumberModule,
    NzInputModule,
    NzSegmentedModule,
    NzDatePickerModule,
    StaysStatusComponent,
    LocationSelectsComponent,
    NecessaryStayToggleComponent,
    UsersSelectComponent,
    FindHotelComponent,
    StayAttachmentsComponent,
    AccomodationPrefillComponent,
    NzCollapseModule,
  ],
  templateUrl: './stays-drawer-form.component.html',
  styleUrl: './stays-drawer-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StaysDrawerFormComponent implements OnInit, OnDestroy {
  private checkInDateTimeControl = new FormControl(new Date(), [
    StartDateTimeValidator(
      TimelineStayManagementDrawerFormFieldEnum.CheckOutDateTime,
    ),
    Validators.required,
  ]);

  private checkOutDateTimeControl = new FormControl(new Date(), [
    EndDateTimeValidator(
      TimelineStayManagementDrawerFormFieldEnum.CheckInDateTime,
    ),
    Validators.required,
  ]);

  private timeFrameControl = new FormControl<DateRange | null>(
    [new Date(), new Date()],
    [Validators.required],
  );

  protected formControls: TimelineStaysDrawerFormControls = {
    [TimelineStayManagementDrawerFormFieldEnum.IsOverNightStayRequired]:
      new FormControl<boolean>(false, [Validators.required]),
    [TimelineStayManagementDrawerFormFieldEnum.LocationId]:
      new FormControl<string>('', [Validators.required]),
    [TimelineStayManagementDrawerFormFieldEnum.StayStatus]:
      new FormControl<StayStatus>(StayStatus.Open, [Validators.required]),
    [TimelineStayManagementDrawerFormFieldEnum.StayType]:
      new FormControl<StayType>(StayType.Hotel, [Validators.required]),
    [TimelineStayManagementDrawerFormFieldEnum.TimeFrame]:
      this.timeFrameControl,
    [TimelineStayManagementDrawerFormFieldEnum.CheckInDateTime]:
      this.checkInDateTimeControl,
    [TimelineStayManagementDrawerFormFieldEnum.CheckOutDateTime]:
      this.checkOutDateTimeControl,
    [TimelineStayManagementDrawerFormFieldEnum.PriceTotal]: new FormControl(0),
    [TimelineStayManagementDrawerFormFieldEnum.PaidNights]: new FormControl(1),
    [TimelineStayManagementDrawerFormFieldEnum.AccomodationName]:
      new FormControl('', [WhiteSpaceValidator]),
    [TimelineStayManagementDrawerFormFieldEnum.Address]:
      new FormControl<string>(''),
    [TimelineStayManagementDrawerFormFieldEnum.PhoneNumber]: new FormControl(
      '',
      [WhiteSpaceValidator],
    ),
    [TimelineStayManagementDrawerFormFieldEnum.Note]: new FormControl('', [
      WhiteSpaceValidator,
    ]),
    [TimelineStayManagementDrawerFormFieldEnum.UserId]: new FormControl('', [
      Validators.required,
    ]),
    [TimelineStayManagementDrawerFormFieldEnum.CancellationDate]:
      new FormControl(null),
    [TimelineStayManagementDrawerFormFieldEnum.Attachments]: new FormControl(
      null,
    ),
    [TimelineStayManagementDrawerFormFieldEnum.CostCenter]: new FormControl(
      null,
    ),
    [TimelineStayManagementDrawerFormFieldEnum.Creditor]: new FormControl(null),
    [TimelineStayManagementDrawerFormFieldEnum.Email]: new FormControl(null),
    [TimelineStayManagementDrawerFormFieldEnum.InvoiceReference]:
      new FormControl(null),
    [TimelineStayManagementDrawerFormFieldEnum.StandardRate]: new FormControl(
      null,
    ),
  };
  protected form: TimelineStaysDrawerForm = new FormGroup(this.formControls, [
    checkInOutDateTimeValidator(),
  ]);

  protected segmentOptions = [
    {
      label: this.translate.instant(
        'page.shift_timeline.hotel_stay.drawer.form.accomodation_type.hotel',
      ),
      value: StayType.Hotel,
    },
    {
      label: this.translate.instant(
        'page.shift_timeline.hotel_stay.drawer.form.accomodation_type.company_room',
      ),
      value: StayType.CompanyRoom,
    },
    {
      label: this.translate.instant(
        'page.shift_timeline.hotel_stay.drawer.form.accomodation_type.private',
      ),
      value: StayType.Private,
    },
  ];
  protected TimelineStayManagementDrawerFormFieldEnum =
    TimelineStayManagementDrawerFormFieldEnum;
  protected selectedStayTypeIndex = 0;
  private subscriptions = new Subscription();
  @Input({ required: true }) prefillValues:
    | Partial<TimelineStayManagementDrawerFormValue>
    | undefined = {};

  @Input() showIgnoreStayToggle = false;

  @Input() showCancelledStatus = false;

  @Input() sector: Sector | null | undefined;

  @Input() set isUserFieldReadOnly(value: boolean) {
    if (value) {
      this.form.get(TimelineStayManagementDrawerFormFieldEnum.UserId)?.disable({
        onlySelf: true,
        emitEvent: false,
      });
    } else {
      this.form.get(TimelineStayManagementDrawerFormFieldEnum.UserId)?.enable({
        onlySelf: true,
        emitEvent: false,
      });
    }
  }

  @Output()
  value = new EventEmitter<Partial<TimelineStayManagementDrawerFormValue>>();

  @Output()
  isInvalid = new EventEmitter<boolean>();

  @Output()
  failedToFillForm = new EventEmitter();

  @Output()
  handleInvoice = new EventEmitter<NzUploadFile[]>();

  @Output()
  handleAttachments = new EventEmitter<NzUploadFile[]>();

  previousShiftLocation$!: Observable<GeoLocation>;

  DateTimeFormat = DateTimeFormat;

  invoiceAttachments: StayAttachment[] = [];
  otherAttachments: StayAttachment[] = [];
  private isUpdatingDateTime = false;

  constructor(
    private readonly locationsStoreService: LocationsStoreService,
    private readonly translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.fillForm();
    this.filterAttachments();

    this.subscribeAndHandleTimeFrameAndCheckInOutDateTimeChanges(
      this.timeFrameControl,
      this.checkInDateTimeControl,
      this.checkOutDateTimeControl,
    );

    this.subscriptions.add(
      this.form.statusChanges.subscribe(() => {
        this.isInvalid.emit(this.form.invalid);
      }),
    );

    this.subscriptions.add(
      this.form.valueChanges.pipe(debounceTime(500)).subscribe(() => {
        if (this.form.valid) {
          this.value.emit(this.form.getRawValue());
        }
      }),
    );
  }

  private subscribeAndHandleTimeFrameAndCheckInOutDateTimeChanges(
    timeFrameControl: AbstractControl<DateRange | null>,
    checkInDateTimeControl: AbstractControl<Date | null>,
    checkOutDateTimeControl: AbstractControl<Date | null>,
  ): void {
    this.subscriptions.add(
      combineLatest([
        timeFrameControl.valueChanges.pipe(startWith(timeFrameControl.value)),
        checkInDateTimeControl.valueChanges.pipe(
          startWith(checkInDateTimeControl.value),
        ),
        checkOutDateTimeControl.valueChanges.pipe(
          startWith(checkOutDateTimeControl.value),
        ),
      ])
        .pipe(
          debounceTime(500),
          filter(() => !this.isUpdatingDateTime),
        )
        .subscribe(([timeFrame, checkInDateTime, checkOutDateTime]) => {
          if (timeFrame && checkInDateTime && checkOutDateTime) {
            this.isUpdatingDateTime = true;

            try {
              const correctedCheckInDateTime = set(timeFrame[0], {
                hours: checkInDateTime.getHours(),
                minutes: checkInDateTime.getMinutes(),
              });
              const correctedCheckOutDateTime = set(timeFrame[1], {
                hours: checkOutDateTime.getHours(),
                minutes: checkOutDateTime.getMinutes(),
              });

              checkInDateTimeControl.setValue(correctedCheckInDateTime, {
                emitEvent: false,
              });
              checkOutDateTimeControl.setValue(correctedCheckOutDateTime, {
                emitEvent: false,
              });
              timeFrameControl.setValue(
                [correctedCheckInDateTime, correctedCheckOutDateTime],
                {
                  emitEvent: false,
                },
              );

              this.form.updateValueAndValidity();
            } finally {
              this.isUpdatingDateTime = false;
            }
          }
        }),
    );
  }

  protected setLocationId(value: {
    location: GeoLocation | null;
    locationId: string;
  }): void {
    if (value) {
      this.form
        .get(TimelineStayManagementDrawerFormFieldEnum.LocationId)
        ?.setValue(value.location?.id as string);
      this.form.markAsDirty();
    }
  }

  private disableControlsBasedOnOverNightRequiredValue(value: boolean): void {
    if (!value) {
      this.form.enable();
    } else {
      this.form.disable();
      this.enableControls([
        TimelineStayManagementDrawerFormFieldEnum.IsOverNightStayRequired,
        TimelineStayManagementDrawerFormFieldEnum.UserId,
      ]);
    }
  }

  protected toggleFormState(value: boolean): void {
    this.form.controls[
      TimelineStayManagementDrawerFormFieldEnum.IsOverNightStayRequired
    ].patchValue(value);
    this.form.markAsDirty();
    this.disableControlsBasedOnOverNightRequiredValue(value);
  }

  protected setAddress(value: LocationChangedEvent): void {
    if (value.location) {
      this.form.markAsDirty();
      this.form
        .get(TimelineStayManagementDrawerFormFieldEnum.Address)
        ?.setValue(value.location.name);
    }
  }

  protected updateFormSelectedStayStatus(selectedStatus: StayStatus): void {
    const stayStatus = this.form.get(
      TimelineStayManagementDrawerFormFieldEnum.StayStatus,
    );
    if (stayStatus) {
      this.form.markAsDirty();
      stayStatus.setValue(selectedStatus);
    }
  }

  protected updateFormStateAndSelectedOption(
    selectedOptionIndex: number,
  ): void {
    this.form.markAsDirty();
    this.selectedStayTypeIndex = selectedOptionIndex;
    const selectedSegmentValue = this.segmentOptions[selectedOptionIndex].value;
    const stayType = this.form.get(
      TimelineStayManagementDrawerFormFieldEnum.StayType,
    );
    if (stayType) {
      stayType.setValue(selectedSegmentValue);
    }
  }

  private enableControls(
    controlNames: TimelineStayManagementDrawerFormFieldEnum[],
  ): void {
    controlNames.forEach((name) => {
      const control = this.form.get(name);
      if (control) {
        control.enable();
      }
    });
  }

  private fillForm(): void {
    if (this.prefillValues) {
      this.previousShiftLocation$ =
        this.locationsStoreService.getLocationFromCache(
          this.prefillValues[
            TimelineStayManagementDrawerFormFieldEnum.LocationId
          ] as string,
        );
      this.setSelectedStayType();
      this.form.patchValue(this.prefillValues);
      if (this.form.valid) {
        this.value.emit(this.form.getRawValue());
      }
    } else {
      this.failedToFillForm.emit();
    }
  }

  private setSelectedStayType(): void {
    const prefillStayType =
      this.prefillValues?.[TimelineStayManagementDrawerFormFieldEnum.StayType];
    if (prefillStayType) {
      const stayTypeValues = Object.values(StayType);
      const index = stayTypeValues.indexOf(prefillStayType);
      if (index > -1) {
        this.selectedStayTypeIndex = index;
      }
    }
  }

  private filterAttachments(): void {
    const stayAttachments =
      this.form.get(TimelineStayManagementDrawerFormFieldEnum.Attachments)
        ?.value || [];
    if (stayAttachments) {
      this.invoiceAttachments = stayAttachments.filter(
        (attachment) => attachment.isInvoice,
      );
      this.otherAttachments = stayAttachments.filter(
        (attachment) => !attachment.isInvoice,
      );
    }
  }

  protected updateFormValues(
    value: TimelineStayManagementDrawerFormValue,
  ): void {
    this.form.patchValue(value);
  }

  protected setAccomodationName(value: string): void {
    if (value) {
      this.form.markAsDirty();
      this.form
        .get(TimelineStayManagementDrawerFormFieldEnum.AccomodationName)
        ?.setValue(value);
    }
  }

  ngOnDestroy(): void {
    this.form.reset();
    this.subscriptions.unsubscribe();
  }
}

function checkInOutDateTimeValidator(): ValidatorFn {
  return (
    control: AbstractControl<TimelineStayManagementDrawerFormValue>,
  ): ValidationErrors | null => {
    const checkInDateTimeControl = control.get(
      TimelineStayManagementDrawerFormFieldEnum.CheckInDateTime,
    );
    const checkOutDateTimeControl = control.get(
      TimelineStayManagementDrawerFormFieldEnum.CheckOutDateTime,
    );

    if (checkInDateTimeControl && checkOutDateTimeControl) {
      const checkInDateTime: Date | null = checkInDateTimeControl.value;
      const checkOutDateTime: Date | null = checkOutDateTimeControl.value;
      checkInDateTimeControl.markAsTouched();
      checkOutDateTimeControl.markAsTouched();

      if (!checkInDateTime) {
        checkInDateTimeControl.setErrors({
          isInvalid: true,
        });
      }
      if (!checkOutDateTime) {
        checkOutDateTimeControl.setErrors({
          isInvalid: true,
        });
      }

      if (
        checkInDateTime &&
        checkOutDateTime &&
        (isEqual(checkInDateTime, checkOutDateTime) ||
          isAfter(checkInDateTime, checkOutDateTime))
      ) {
        checkInDateTimeControl.setErrors({
          isInvalid: true,
        });
        checkOutDateTimeControl.setErrors({
          isInvalid: true,
        });
      } else {
        checkInDateTimeControl.setErrors(null);
        checkOutDateTimeControl.setErrors(null);
      }
    }

    return null;
  };
}
