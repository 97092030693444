import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Activity, Shift } from '@wilson/interfaces';
import { combineLatest, Observable, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ActivityCommentsService } from '../../activity-comments.service';
import { ActivityCommentsComponent } from '../activity-comments/activity-comments.component';

@Component({
  selector: 'wilson-activity-comment-button',
  templateUrl: './activity-comment-button.component.html',
  styleUrls: ['./activity-comment-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityCommentButtonComponent implements OnChanges {
  @Input() activity: Activity;
  @Input() shift: Shift;
  @Input() hasUpdatePermissions = false;
  seenLatestComment$: Observable<{ seen: boolean | null }>;

  constructor(
    private readonly ngbModal: NgbModal,
    private readonly commentService: ActivityCommentsService,
    private readonly store: Store,
  ) {}

  ngOnChanges(): void {
    this.seenLatestComment$ = timer(0, 30000).pipe(
      switchMap(() =>
        combineLatest([
          this.commentService.getLatestForActivity(this.activity.id as string),
          this.store.select<string>((state) => state.auth.userId),
        ]),
      ),
      map(([comment, userId]) => {
        if (!comment) return { seen: null };
        if (comment.senderId === userId) return { seen: true };

        return { seen: !!comment.seenByIds?.includes(userId) };
      }),
    );
  }

  public async openActivityCommentModal() {
    const modalRef = this.ngbModal.open(ActivityCommentsComponent, {
      size: 'l',
      centered: true,
    });
    (modalRef.componentInstance as ActivityCommentsComponent).activity =
      this.activity;
    (modalRef.componentInstance as ActivityCommentsComponent).shift =
      this.shift;
    (
      modalRef.componentInstance as ActivityCommentsComponent
    ).hasUpdatePermissions = this.hasUpdatePermissions;
  }
}
