import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { WorktimeUtilizationDTO } from '@wilson/interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WorkTimeUtilizationsGatewayService {
  protected readonly path = 'v1/kpis';
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(ConfigService)
    private readonly config: ConfigOptions,
  ) {}

  getWorkTimeUtilizations(
    userIds: string[],
    timeRange: {
      startDatetime: string;
      endDatetime: string;
    },
  ): Observable<WorktimeUtilizationDTO> {
    return this.httpClient.post<WorktimeUtilizationDTO>(
      `${this.config.host}/${this.path}/work-time-utilizations`,
      {
        userIds,
        from: timeRange.startDatetime,
        to: timeRange.endDatetime,
      },
    );
  }
}
