import {
  AccurateActivityTimeDetails,
  AccurateDateInterval,
  Activity,
  ActivityDeviationStatus,
  ActivityReport,
  ActivityReportCategory,
  ActivityWithCategoryAndLocationsAndReportedDetails,
  DateStringInterval,
} from '@wilson/interfaces';
import { addMinutes, differenceInMinutes, isAfter, isBefore } from 'date-fns';
import { createDateTimeWithoutSeconds } from './determine-assignments-overlap';

type AccurateActivityTiming = {
  date: string;
  type: ActivityDeviationStatus;
  timeDifference: number;
};

export function getMostLogicalStartDatetimeV2(
  activity: ActivityWithCategoryAndLocationsAndReportedDetails,
  isDebugEnabled: boolean,
): AccurateActivityTiming {
  const mostRecentEndDatetime = new Date(
    getMostRecentEndDatetimeV2(activity).date,
  );

  const plannedDate = {
    date: activity.startDatetime,
    type: ActivityDeviationStatus.Planned,
    timeDifference: 0,
  };

  if (activity.reportedStartDatetime) {
    const reportedStartDatetime = new Date(activity.reportedStartDatetime);
    return calculateReportedDateData(
      reportedStartDatetime,
      mostRecentEndDatetime,
      plannedDate,
      activity,
      isDebugEnabled,
    );
  }

  return determineAdjustedStartDatetime(
    activity,
    isDebugEnabled,
    mostRecentEndDatetime,
    plannedDate,
  );
}

export function getMostLogicalStartDatetime(
  activity: Activity,
  isDebugEnabled: boolean,
): AccurateActivityTiming {
  const mostRecentEndDatetime = new Date(
    getMostRecentEndDatetime(activity).date,
  );

  const plannedDate = {
    date: activity.startDatetime,
    type: ActivityDeviationStatus.Planned,
    timeDifference: 0,
  };

  if (activity.activityReports?.length) {
    const reportedStartDatetime = getReportedStartDatetime(
      activity.activityReports,
    );

    if (reportedStartDatetime) {
      return calculateReportedDateData(
        reportedStartDatetime,
        mostRecentEndDatetime,
        plannedDate,
        activity,
        isDebugEnabled,
      );
    } else {
      return plannedDate;
    }
  } else {
    return determineAdjustedStartDatetime(
      activity,
      isDebugEnabled,
      mostRecentEndDatetime,
      plannedDate,
    );
  }
}

export function calculateActualShiftStartTime(
  activities: Activity[],
  isDebugEnabled: boolean,
): AccurateActivityTimeDetails {
  const result: AccurateActivityTimeDetails = {
    date: new Date('9999-12-31T23:59:59.999Z').toISOString(),
    location: null,
    timeDifference: 0,
    type: ActivityDeviationStatus.Planned,
  };

  activities.forEach((activity) => {
    const recentStartDateData = getMostLogicalStartDatetime(
      activity,
      isDebugEnabled,
    );

    if (recentStartDateData.date < result.date) {
      result.date = recentStartDateData.date;
      result.timeDifference = recentStartDateData.timeDifference;
      result.location = activity.startLocation;
      result.type = recentStartDateData.type;
    }
  });

  return result;
}

export function determineShiftInterval(
  items: DateStringInterval[],
  isDebugEnabled: boolean,
): AccurateDateInterval {
  let start = new Date('9999-12-31T23:59:59.999Z').toISOString();
  let end = new Date(0).toISOString();

  items.forEach((item) => {
    const activityStart = getMostLogicalStartDatetime(
      item as Activity,
      isDebugEnabled,
    ).date;
    const activityEnd = getMostLogicalEndDatetime(
      item as Activity,
      isDebugEnabled,
    ).date;

    if (activityStart < start) start = activityStart;
    if (activityEnd > end) end = activityEnd;
  });

  return {
    accurateStartDateTime: createDateTimeWithoutSeconds(start),
    accurateEndDateTime: createDateTimeWithoutSeconds(end),
  };
}

export function getMostRecentStartDatetimeV2(
  activity: Pick<
    ActivityWithCategoryAndLocationsAndReportedDetails,
    'reportedStartDatetime' | 'startDatetime'
  >,
): AccurateActivityTiming {
  const reportedStartDatetime = activity.reportedStartDatetime;
  if (reportedStartDatetime) {
    const timeDifference = differenceInMinutes(
      new Date(reportedStartDatetime),
      new Date(activity.startDatetime),
    );
    const reportedDateData = {
      date: new Date(reportedStartDatetime).toISOString(),
      type: ActivityDeviationStatus.Reported,
      timeDifference,
    };
    return reportedDateData;
  } else {
    const deviatedStartData = getDeviatedStartDatetime(activity);
    const plannedDate = {
      date: activity.startDatetime,
      type: ActivityDeviationStatus.Planned,
      timeDifference: 0,
    };

    return deviatedStartData || plannedDate;
  }
}

export function getMostRecentStartDatetime(
  activity: Activity,
): AccurateActivityTiming {
  if (activity.activityReports?.length) {
    const reportedStartDatetime = getReportedStartDatetime(
      activity.activityReports,
    );
    if (reportedStartDatetime) {
      const timeDifference = differenceInMinutes(
        reportedStartDatetime,
        new Date(activity.startDatetime),
      );
      const reportedDateData = {
        date: reportedStartDatetime.toISOString(),
        type: ActivityDeviationStatus.Reported,
        timeDifference,
      };
      return reportedDateData;
    }
  }

  const deviatedStartData = getDeviatedStartDatetime(activity);
  const plannedDate = {
    date: activity.startDatetime,
    type: ActivityDeviationStatus.Planned,
    timeDifference: 0,
  };

  return deviatedStartData || plannedDate;
}

function getReportedStartDatetime(
  activityReports: ActivityReport[],
): Date | null {
  const sortedActivityReports = [...activityReports].sort((a, b) => {
    if (!a.updatedAt && !b.updatedAt) return 0;
    if (!a.updatedAt) return 1;
    if (!b.updatedAt) return -1;

    return b.updatedAt < a.updatedAt ? -1 : 1;
  });

  const lastStartReport = sortedActivityReports.find(
    (x) => x.reportCategory === ActivityReportCategory.Start,
  );
  return lastStartReport ? new Date(lastStartReport.dateTime) : null;
}

function getDeviatedStartDatetime(
  activity: Pick<Activity, 'serviceDeviations' | 'startDatetime'>,
): AccurateActivityTiming | null {
  if (activity.serviceDeviations) {
    const departureDeviation = activity.serviceDeviations.find(
      (d) => d.affectedStop === 'departure',
    );
    if (departureDeviation) {
      const deviatedDate = addMinutes(
        new Date(activity.startDatetime),
        departureDeviation.deviationInMinutes,
      ).toISOString();

      return {
        date: deviatedDate,
        type: ActivityDeviationStatus.Deviated,
        timeDifference: departureDeviation.deviationInMinutes,
      };
    }
  }
  return null;
}

export function calculateActualShiftEndTime(
  activities: Activity[],
  isDebugEnabled: boolean,
): AccurateActivityTimeDetails {
  const result: AccurateActivityTimeDetails = {
    date: new Date(0).toISOString(),
    timeDifference: 0,
    location: null,
    type: ActivityDeviationStatus.Planned,
  };

  activities.forEach((activity) => {
    const recentEndDateData = getMostLogicalEndDatetime(
      activity,
      isDebugEnabled,
    );

    if (recentEndDateData.date > result.date) {
      result.date = recentEndDateData.date;
      result.timeDifference = recentEndDateData.timeDifference;
      result.location = activity.endLocation;
      result.type = recentEndDateData.type;
    }
  });
  return result;
}

export function getMostLogicalEndDatetimeV2(
  activity: ActivityWithCategoryAndLocationsAndReportedDetails,
  isDebugEnabled: boolean,
): AccurateActivityTiming {
  const mostRecentStartDatetime = new Date(
    getMostRecentStartDatetimeV2(activity).date,
  );

  const plannedEndDate = {
    date: activity.endDatetime,
    timeDifference: 0,
    type: ActivityDeviationStatus.Planned,
  };

  const reportedEndDatetime = activity.reportedEndDatetime;
  if (reportedEndDatetime) {
    return getReportedEndDateData(
      new Date(reportedEndDatetime),
      mostRecentStartDatetime,
      activity,
      plannedEndDate,
      isDebugEnabled,
    );
  } else {
    return resolveActivityDeviatedEndDate(
      activity,
      isDebugEnabled,
      mostRecentStartDatetime,
      plannedEndDate,
    );
  }
}

export function getMostLogicalEndDatetime(
  activity: Activity,
  isDebugEnabled: boolean,
): AccurateActivityTiming {
  const mostRecentStartDatetime = new Date(
    getMostRecentStartDatetime(activity).date,
  );

  const plannedEndDate = {
    date: activity.endDatetime,
    timeDifference: 0,
    type: ActivityDeviationStatus.Planned,
  };

  if (activity.activityReports?.length) {
    const reportedEndDatetime = getReportedEndDatetime(
      activity.activityReports,
    );
    if (reportedEndDatetime) {
      return getReportedEndDateData(
        reportedEndDatetime,
        mostRecentStartDatetime,
        activity,
        plannedEndDate,
        isDebugEnabled,
      );
    } else {
      return plannedEndDate;
    }
  } else {
    return resolveActivityDeviatedEndDate(
      activity,
      isDebugEnabled,
      mostRecentStartDatetime,
      plannedEndDate,
    );
  }
}

export function getMostRecentEndDatetimeV2(
  activity: ActivityWithCategoryAndLocationsAndReportedDetails,
): AccurateActivityTiming {
  const reportedEndDatetime = activity.reportedEndDatetime;
  if (reportedEndDatetime) {
    const timeDifference = differenceInMinutes(
      new Date(reportedEndDatetime),
      new Date(activity.endDatetime),
    );

    const reportedEndDateData = {
      date: new Date(reportedEndDatetime).toISOString(),
      type: ActivityDeviationStatus.Reported,
      timeDifference,
    };
    return reportedEndDateData;
  } else {
    const deviatedEndData = getDeviatedEndDatetime(activity);
    const plannedEndDate = {
      date: activity.endDatetime,
      timeDifference: 0,
      type: ActivityDeviationStatus.Planned,
    };

    return deviatedEndData || plannedEndDate;
  }
}

export function getMostRecentEndDatetime(
  activity: Activity,
): AccurateActivityTiming {
  if (activity.activityReports?.length) {
    const reportedEndDatetime = getReportedEndDatetime(
      activity.activityReports,
    );
    if (reportedEndDatetime) {
      const timeDifference = differenceInMinutes(
        reportedEndDatetime,
        new Date(activity.endDatetime),
      );

      const reportedEndDateData = {
        date: reportedEndDatetime.toISOString(),
        type: ActivityDeviationStatus.Reported,
        timeDifference,
      };
      return reportedEndDateData;
    }
  }

  const deviatedEndData = getDeviatedEndDatetime(activity);
  const plannedEndDate = {
    date: activity.endDatetime,
    timeDifference: 0,
    type: ActivityDeviationStatus.Planned,
  };

  return deviatedEndData || plannedEndDate;
}

function getReportedEndDatetime(
  activityReports: ActivityReport[],
): Date | null {
  const sortedActivityReports = [...activityReports].sort((a, b) => {
    if (!a.updatedAt && !b.updatedAt) return 0;
    if (!a.updatedAt) return 1;
    if (!b.updatedAt) return -1;

    return b.updatedAt < a.updatedAt ? -1 : 1;
  });

  const lastEndReport = sortedActivityReports.find(
    (x) => x.reportCategory === ActivityReportCategory.End,
  );
  return lastEndReport ? new Date(lastEndReport.dateTime) : null;
}

function getDeviatedEndDatetime(
  activity: Pick<Activity, 'serviceDeviations' | 'endDatetime'>,
): AccurateActivityTiming | null {
  if (activity.serviceDeviations) {
    const arrivalDeviation = activity.serviceDeviations.find(
      (d) => d.affectedStop === 'arrival',
    );
    if (arrivalDeviation) {
      const deviatedDate = addMinutes(
        new Date(activity.endDatetime),
        arrivalDeviation.deviationInMinutes,
      ).toISOString();

      return {
        date: deviatedDate,
        type: ActivityDeviationStatus.Deviated,
        timeDifference: arrivalDeviation.deviationInMinutes,
      };
    }
  }
  return null;
}

function calculateReportedDateData(
  reportedStartDatetime: Date,
  mostRecentEndDatetime: Date,
  plannedDate: AccurateActivityTiming,
  activity: Pick<Activity, 'startDatetime' | 'id' | 'serviceId'>,
  isDebugEnabled: boolean,
): AccurateActivityTiming {
  if (isBefore(reportedStartDatetime, mostRecentEndDatetime)) {
    const timeDifference = differenceInMinutes(
      reportedStartDatetime,
      new Date(activity.startDatetime),
    );

    return {
      date: reportedStartDatetime.toISOString(),
      type: ActivityDeviationStatus.Reported,
      timeDifference,
    };
  } else {
    if (isDebugEnabled) {
      console.info(
        `Bad reported start time in activity ${activity.id} from service ${activity.serviceId}. Reported start time cannot be after most recent end time. Using planned start time instead.`,
      );
    }
    return plannedDate;
  }
}

function determineAdjustedStartDatetime(
  activity: Pick<
    Activity,
    'serviceDeviations' | 'startDatetime' | 'serviceId' | 'id'
  >,
  isDebugEnabled: boolean,
  mostRecentEndDatetime: Date,
  plannedDate: AccurateActivityTiming,
): AccurateActivityTiming {
  const deviatedStartData = getDeviatedStartDatetime(activity);

  if (deviatedStartData) {
    if (isBefore(new Date(deviatedStartData.date), mostRecentEndDatetime)) {
      return deviatedStartData;
    } else {
      if (isDebugEnabled) {
        console.info(
          `Bad deviated start time in activity ${activity.id} from service ${activity.serviceId}. Deviated start time cannot be after deviated end time or planned end time.  Using planned start time instead.`,
        );
      }
      return plannedDate;
    }
  } else {
    return plannedDate;
  }
}

function getReportedEndDateData(
  reportedEndDatetime: Date | null,
  mostRecentStartDatetime: Date,
  activity: Pick<Activity, 'endDatetime' | 'id' | 'serviceId'>,
  plannedEndDate: AccurateActivityTiming,
  isDebugEnabled: boolean,
): AccurateActivityTiming {
  if (reportedEndDatetime) {
    if (isAfter(reportedEndDatetime, mostRecentStartDatetime)) {
      const timeDifference = differenceInMinutes(
        reportedEndDatetime,
        new Date(activity.endDatetime),
      );

      return {
        date: reportedEndDatetime.toISOString(),
        type: ActivityDeviationStatus.Reported,
        timeDifference,
      };
    } else {
      if (isDebugEnabled) {
        console.info(
          `Bad reported end time in activity ${activity.id} from service ${activity.serviceId}. Reported end time cannot be before most recent start time. Using planned end time instead.`,
        );
      }
      return plannedEndDate;
    }
  } else {
    return plannedEndDate;
  }
}

function resolveActivityDeviatedEndDate(
  activity: Pick<
    Activity,
    'serviceDeviations' | 'endDatetime' | 'serviceId' | 'id'
  >,
  isDebugEnabled: boolean,
  mostRecentStartDatetime: Date,
  plannedEndDate: AccurateActivityTiming,
): AccurateActivityTiming {
  const deviatedEndData = getDeviatedEndDatetime(activity);

  if (deviatedEndData) {
    if (isAfter(new Date(deviatedEndData.date), mostRecentStartDatetime)) {
      return deviatedEndData;
    } else {
      if (isDebugEnabled) {
        console.info(
          `Bad deviated end time in activity ${activity.id} from service ${activity.serviceId}. Deviated end time cannot be before deviated start time or planned start time. Using planned end time instead.`,
        );
      }
      return plannedEndDate;
    }
  } else {
    return plannedEndDate;
  }
}
