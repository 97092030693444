import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ManyEntity } from '@wilson/base';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { ShiftState, ShiftWithAzmAttributes } from '@wilson/interfaces';
import { stringify } from 'qs';
import { Observable } from 'rxjs';

export interface NumberRangeDto {
  min: number;
  max: number;
}
export interface GetShiftWorkTimesSearchDto {
  from: string;
  to: string;
  userIds?: string[];
  organizationalUnitIds?: string[];
  deviationRange?: NumberRangeDto;
  hasShiftNote?: boolean;
  hasReportComments?: boolean;
  agreementIds?: string[];
  allActivitiesHaveAgreement?: boolean;
  projectIds?: string[];
  labelIds?: string[];
  shiftState?: ShiftState[];
}

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export interface AzmSortColumns {
  name: SortOrder | undefined;
  startDate: SortOrder | undefined;
  projectId: SortOrder | undefined;
  labels: { _count: SortOrder } | undefined;
  createdFrom: SortOrder | undefined;
  plannedDuration: SortOrder | undefined;
  reportedDuration: SortOrder | undefined;
}

export interface PaginationOptions {
  pageNumber: number;
  pageSize: number;
  orderBy: AzmSortColumns;
}

@Injectable({
  providedIn: 'root',
})
export class ShiftWorkTimesGateway {
  constructor(
    protected readonly http: HttpClient,
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
  ) {}

  public getShifts(
    where: GetShiftWorkTimesSearchDto,
    paginationOptions: PaginationOptions,
  ): Observable<ManyEntity<ShiftWithAzmAttributes>> {
    const params = stringify({
      ...paginationOptions,
    });

    return this.http.post<ManyEntity<ShiftWithAzmAttributes>>(
      `${this.config.host}/v2/shift-work-times?${params}`,
      where,
    );
  }
}
