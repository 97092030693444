import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import {
  NumberOfShiftPerDay,
  PublicationStatus,
  ShiftConfirmationStatus,
  UnassignedShift,
  UserTimelinesWithActivitiesPayload,
  UserTimelinesWithoutActivitiesPayload,
} from '@wilson/interfaces';
import { Observable } from 'rxjs';

export interface UserFiltersDTO {
  from: Date | string;
  to: Date | string;
  userFilters?: {
    userIds?: string[];
    roleIds?: string[];
    organizationalUnitIds?: string[];
    labelIds?: string[];
    professionIds?: string[];
    homeLocationIds?: string[];
  };
  shiftFilters?: {
    categoryIds?: string[];
    publicationStatus?: PublicationStatus[];
    confirmationStatus?: ShiftConfirmationStatus[];
    projectIds?: string[];
    organizationalUnitIds?: string[];
    labelIds?: string[];
    professionIds?: string[];
    agreementIds?: string[];
    clientIds?: string[];
  };
  absenceFilters?: {
    categoryIds?: string[];
  };
}

@Injectable({
  providedIn: 'root',
})
export class UserTimelinesGateway {
  constructor(
    protected readonly http: HttpClient,
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
  ) {}

  public getFilteredUsers(options: UserFiltersDTO): Observable<string[]> {
    return this.http.post<string[]>(
      `${this.config.host}/v3/user-timelines/filter-users`,
      options,
    );
  }

  public getUserTimelinesWithActivities(
    options: UserFiltersDTO,
  ): Observable<UserTimelinesWithActivitiesPayload[]> {
    return this.getUserTimelinesV3<UserTimelinesWithActivitiesPayload>({
      options,
      withActivities: true,
    });
  }

  public getUserTimelinesWithoutActivities(
    options: UserFiltersDTO,
  ): Observable<UserTimelinesWithoutActivitiesPayload[]> {
    return this.getUserTimelinesV3<UserTimelinesWithoutActivitiesPayload>({
      options,
      withActivities: false,
    });
  }

  private getUserTimelinesV3<T>({
    withActivities,
    options,
  }: {
    withActivities: boolean;
    options: UserFiltersDTO;
  }): Observable<T[]> {
    const params = new HttpParams({
      fromString: `withActivities=${withActivities}`,
    });
    return this.http.post<T[]>(
      `${this.config.host}/v3/user-timelines`,
      options,
      { params },
    );
  }

  public getUnassignedTimelinesShiftsV3(
    options: UserFiltersDTO,
  ): Observable<UnassignedShift[]> {
    return this.http.post<UnassignedShift[]>(
      `${this.config.host}/v3/user-timelines/filter-unassigned-shifts`,
      {
        ...options,
      },
    );
  }

  public getUnassignedTimelinesShiftsCount(
    options: UserFiltersDTO,
  ): Observable<NumberOfShiftPerDay[]> {
    return this.http.post<NumberOfShiftPerDay[]>(
      `${this.config.host}/v3/user-timelines/count-unassigned-shifts`,
      {
        ...options,
      },
    );
  }
}
