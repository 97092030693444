@let hasShiftReadPermission = RoleAction.Read | ablePure :
RolePermissionSubject.Shift | async; @if(hasShiftReadPermission){

<nz-collapse [nzBordered]="false" nzGhost>
  <nz-collapse-panel
    #wageDataPanel
    [nzHeader]="wageDataSectionHeader"
    [nzActive]="isExpandedOnOpen"
    [nzShowArrow]="false"
    class="collapse-panel"
    [nzExtra]="wageDataCollapseIcon"
  >
    <wilson-shift-wage-data
      class="mt-2"
      [shift]="shift"
      [areInputsDisabled]="areInputsDisabled"
    ></wilson-shift-wage-data>
  </nz-collapse-panel>
</nz-collapse>

<ng-template #wageDataSectionHeader>
  <span class="header-text">
    {{ 'shift_drawer.wage_data' | translate }}
  </span>
</ng-template>

<ng-template #wageDataCollapseIcon>
  <i
    class="fas fa-chevron-up"
    [ngClass]="wageDataPanel.nzActive ? 'rotate-180' : 'rotate-0'"
  ></i>
</ng-template>
}
