import { Injectable } from '@angular/core';
import {
  Absence,
  AccurateDateInterval,
  AccurateUnassignedItem,
  Activity,
  ActivityWithCategoryAndLocationsAndReportedDetails,
  DateInterval,
  DateStringInterval,
  Shift,
} from '@wilson/interfaces';
import {
  consoleInfo,
  isDebugEnabled,
} from '@wilson/non-domain-specific/logger/logger';
import {
  createDateTimeWithoutSeconds,
  determineShiftInterval,
  getMostLogicalEndDatetimeV2,
  getMostLogicalStartDatetimeV2,
  isOverlapDetected,
  sortIntervalsByAccurateStartTimeAndLength,
} from '@wilson/non-domain-specific/overlap-helpers';

export type ActivityWithOverlapLevel = Activity & {
  overlapLevel: number;
  id: string;
};

export type ShiftWithActivitiesAndOverlap = Shift & {
  id: string;
  startDatetime: Date;
  endDatetime: Date;
  activities: ActivityWithOverlapLevel[];
  activitiesInRowLevels: ActivityWithOverlapLevel[];
};

interface ActivitiesContainer {
  activities: DateStringInterval[];
}

interface AbsenceDateStringInterval {
  absentFrom: string;
  absentTo: string;
}

type TimeLineInputItem = (
  | ActivitiesContainer
  | AbsenceDateStringInterval
  | DateStringInterval
) & { id: string };

export type AccurateTimelineItem = AccurateDateInterval & {
  id: string;
};

export type AccurateUnassignedDeterminedItem = AccurateUnassignedItem & {
  overlapLevel: number;
};

export type DeterminedItem = DateInterval & { overlapLevel: number };

type AccurateDeterminedItem = AccurateDateInterval & {
  overlapLevel: number;
};

export type DeterminedShiftOrAbsence = (
  | (Shift & {
      id: string;
      activities: Activity[];
    })
  | (Absence & {
      id: string;
    })
) &
  DeterminedItem;

@Injectable({
  providedIn: 'root',
})
export class TimelineOverlapService {
  determineOverlaps<T extends TimeLineInputItem>(
    timelineItem: T[],
  ): (DeterminedItem & T)[] {
    const determinedList: AccurateDeterminedItem[] = [];

    const itemsWithIntervals = this.assignDateIntervals(timelineItem);

    const sortedTimelineItemsWithIntervals = itemsWithIntervals.sort(
      sortIntervalsByAccurateStartTimeAndLength,
    );
    sortedTimelineItemsWithIntervals.forEach((item) => {
      const itemOverlapLevel = this.getItemOverlapLevel(determinedList, item);
      determinedList.push({ ...item, overlapLevel: itemOverlapLevel });
    });

    const newDeterminedList = determinedList.map(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ({ accurateStartDateTime, accurateEndDateTime, ...restOfItem }) =>
        restOfItem,
    );

    return newDeterminedList as (DeterminedItem & T)[];
  }

  getItemOverlapLevel(
    determinedList: AccurateDeterminedItem[],
    item: AccurateTimelineItem,
  ): number {
    determinedList.sort((a, b) => a.overlapLevel - b.overlapLevel);

    let itemOverlapLevel = 0;

    determinedList.forEach((determinedItem) => {
      if (itemOverlapLevel === determinedItem.overlapLevel) {
        try {
          const doItemAndDeterminedItemOverlap = isOverlapDetected(
            item,
            determinedItem,
          );

          if (!doItemAndDeterminedItemOverlap) {
            itemOverlapLevel = determinedItem.overlapLevel;
            return;
          } else {
            itemOverlapLevel = determinedItem.overlapLevel + 1;
          }
        } catch (e) {
          consoleInfo(e);
          consoleInfo(item);
        }
      }
    });

    return itemOverlapLevel;
  }

  private assignDateIntervals(
    timelineItems: TimeLineInputItem[],
  ): AccurateTimelineItem[] {
    const itemsWithDateInterval: AccurateTimelineItem[] = [];

    timelineItems.forEach((item) => {
      if (item && 'activities' in item) {
        const activityContainer = item as ActivitiesContainer;
        if (activityContainer.activities.length < 1) return;

        const containerInterval = determineShiftInterval(
          activityContainer.activities,
          isDebugEnabled(),
        );

        itemsWithDateInterval.push({
          ...(item as object),
          ...containerInterval,
        } as AccurateTimelineItem);
      } else if ('absentFrom' in item) {
        const absence = item as AbsenceDateStringInterval;

        itemsWithDateInterval.push({
          ...(item as object),
          accurateStartDateTime: createDateTimeWithoutSeconds(
            absence.absentFrom,
          ),
          accurateEndDateTime: createDateTimeWithoutSeconds(absence.absentTo),
        } as AccurateTimelineItem);
      } else {
        const accurateStartDateTime = createDateTimeWithoutSeconds(
          getMostLogicalStartDatetimeV2(
            item as ActivityWithCategoryAndLocationsAndReportedDetails,
            isDebugEnabled(),
          ).date,
        );
        const accurateEndDateTime = createDateTimeWithoutSeconds(
          getMostLogicalEndDatetimeV2(
            item as ActivityWithCategoryAndLocationsAndReportedDetails,
            isDebugEnabled(),
          ).date,
        );

        itemsWithDateInterval.push({
          ...(item as object),
          accurateStartDateTime,
          accurateEndDateTime,
        } as AccurateTimelineItem);
      }
    });
    return itemsWithDateInterval;
  }

  getActivitiesInRow(updatedActivities: ActivityWithOverlapLevel[]) {
    const groupedObjects: Record<number, ActivityWithOverlapLevel[]> = {};

    for (const activity of updatedActivities) {
      const level = activity.overlapLevel;
      if (!groupedObjects[level]) {
        groupedObjects[level] = [];
      }

      groupedObjects[level].push(activity);
    }

    const activitiesInRowArray = Object.values(groupedObjects);
    return activitiesInRowArray;
  }
}
