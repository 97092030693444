export enum DateTimeFormat {
  DatabaseDateFormat = 'yyyy-MM-dd',
  TimeFormat = 'HH:mm',
  GermanDateFormat = 'dd.MM.yyyy',
  GermanDateFormatSort = 'dd.MM.yy',
  GermanDateTimeFormat = 'dd.MM.yyyy - HH:mm',
  DatabaseDateTimeFormat = 'yyyy-MM-dd HH:mm',
  LocalTimeFormat = 'yyyy-MM-ddTHH:mm',
  DayAndMonth = 'dd.MM',
  GermanDateFormatWithDay = 'EEEE dd.MM.yyyy',
  MonthFormat = 'yyyy-MM',
  TimeDayAndMonth = 'HH:mm, dd.MM',
  GermanDateTimeHourFormat = 'dd.MM.yy, HH:mm',
  GermanDateTimeHourFormatWithSeconds = 'dd.MM.yyyy - HH:mm:ss',
}
