import { Injectable } from '@angular/core';
import {
  ShiftTimelineDataStateModel,
  ShiftTimelineUnassignedRegionStateModel,
} from '../interfaces';
import { StateContext } from '@ngxs/store';
import { ActivitiesV2Gateway } from '@wilson/api/gateway';
import { catchError, first, map, Observable, of } from 'rxjs';
import { setSeconds } from 'date-fns';
import { TimelineOverlapService } from '@wilson/shift-timeline/services';
import {
  Activity,
  ActivityRow,
  ActivityWithCategoryAndLocationsAndReportedDetails,
  ActivityWithOverlapLevel,
  OperationStatus,
} from '@wilson/interfaces';
import { append, patch } from '@ngxs/store/operators';
import { zipNestedRecords } from '../shift-timeline-data/shift-timeline-data.helpers';
import { NzMessageService } from 'ng-zorro-antd/message';
import { TranslateService } from '@ngx-translate/core';
type ShiftId = string;
@Injectable({
  providedIn: 'root',
})
export class ActivitiesStateLoaderService {
  constructor(
    private activitiesGateway: ActivitiesV2Gateway,
    private timelineOverlapService: TimelineOverlapService,
    private messageService: NzMessageService,
    private translateService: TranslateService,
  ) {}

  loadActivitiesToUnassignedRegionState(
    ctx: StateContext<ShiftTimelineUnassignedRegionStateModel>,
    shiftIds: string[],
  ) {
    const shiftIdsNotCurrentlyInFlight = this.filterUniqueInFlightForShiftIds(
      ctx.getState().loadingActivitiesForShiftIds,
      shiftIds,
    );

    if (shiftIdsNotCurrentlyInFlight.length > 0) {
      ctx.setState(
        patch({
          loadingActivitiesForShiftIds: append(shiftIdsNotCurrentlyInFlight),
        }),
      );

      this.loadActivitiesAndOrganizeIntoRowsInPartition(
        shiftIdsNotCurrentlyInFlight,
      ).forEach((request) => {
        request.subscribe(({ partitionedIds, data }) => {
          const { loadingActivitiesForShiftIds } = ctx.getState();

          ctx.setState(
            patch({
              loadingActivitiesForShiftIds: loadingActivitiesForShiftIds.filter(
                (shiftId) => !partitionedIds.includes(shiftId),
              ),
              shiftActivitiesRows: patch(data),
            }),
          );
        });
      });
    }
  }

  loadActivitiesToAssignedRegionState(
    ctx: StateContext<ShiftTimelineDataStateModel>,
    shiftIds: string[],
  ) {
    const { loadingActivitiesForShiftIds } = ctx.getState();
    const shiftIdsNotCurrentlyInFlight = this.filterUniqueInFlightForShiftIds(
      loadingActivitiesForShiftIds,
      shiftIds,
    );
    if (shiftIdsNotCurrentlyInFlight.length > 0) {
      ctx.setState(
        patch({
          loadingActivitiesForShiftIds: append(shiftIdsNotCurrentlyInFlight),
        }),
      );

      this.loadActivitiesAndOrganizeIntoRowsInPartition(
        shiftIdsNotCurrentlyInFlight,
      ).forEach((request) => {
        request.subscribe(({ partitionedIds, data }) => {
          const usersDeterminedShiftActivities = this.getActivitiesForShiftIds(
            data,
            shiftIdsNotCurrentlyInFlight,
          );
          const { loadingActivitiesForShiftIds } = ctx.getState();

          ctx.setState(
            patch({
              loadingActivitiesForShiftIds: loadingActivitiesForShiftIds.filter(
                (shiftId) => !partitionedIds.includes(shiftId),
              ),
              determinedShiftActivities: zipNestedRecords(
                usersDeterminedShiftActivities,
              ),
            }),
          );
        });
      });
    }
  }

  private filterUniqueInFlightForShiftIds(
    loadingActivitiesForShiftIds: string[],
    shiftIds: string[],
  ) {
    const shiftIdsNotCurrentlyInFlight = shiftIds.filter((id) => {
      return !loadingActivitiesForShiftIds.includes(id);
    });

    return shiftIdsNotCurrentlyInFlight;
  }

  private loadActivitiesAndOrganizeIntoRowsInPartition(
    shiftIds: string[],
  ): Observable<{
    partitionedIds: string[];
    data: Record<ShiftId, ActivityRow[]>;
  }>[] {
    const maxShiftIdsLength = 50;
    const partitionedShiftIds: string[][] = [];

    let iterator = 0;
    do {
      partitionedShiftIds.push(
        shiftIds.slice(iterator, iterator + maxShiftIdsLength),
      );
      iterator += maxShiftIdsLength;
    } while (iterator < shiftIds.length);

    return partitionedShiftIds.map((partitionedIds) => {
      return this.activitiesGateway
        .getActivities<
          Activity & ActivityWithCategoryAndLocationsAndReportedDetails
        >({
          options: {
            relations: ['activityCategory', 'startLocation', 'endLocation'],
            where: {
              shiftId: {
                in: partitionedIds,
              },
              operationalStatus: {
                notIn: [
                  OperationStatus.SkippedByUser,
                  OperationStatus.Cancelled,
                ],
              },
            },
            pageNumber: 1,
            pageSize: 9999,
          },
        })
        .pipe(
          first(),
          map(({ data }) => {
            data.forEach((activity) => {
              activity.startDatetime = setSeconds(
                new Date(activity.startDatetime),
                0,
              ).toISOString();
              activity.endDatetime = setSeconds(
                new Date(activity.endDatetime),
                0,
              ).toISOString();
            });

            const shiftsActivityRecords = this.mapActivitiesToShift(data);

            const mapOfShiftsAndActivitiesInRows: Record<
              ShiftId,
              ActivityRow[]
            > = Object.entries(shiftsActivityRecords).reduce(
              (acc, [key, value]) => {
                const determinedActivities = this.getActivityRows(
                  value as (Activity & { id: string })[],
                );
                acc[key] = determinedActivities;
                return acc;
              },
              {} as Record<ShiftId, ActivityRow[]>,
            );

            return {
              partitionedIds,
              data: mapOfShiftsAndActivitiesInRows,
            };
          }),
          catchError(() => {
            this.messageService.error(
              this.translateService.instant('general.error'),
            );
            return of({
              partitionedIds,
              data: {},
            });
          }),
        );
    });
  }

  getActivityRows(value: (Activity & { id: string })[]): ActivityRow[] {
    const activitiesWithOverlap =
      this.timelineOverlapService.determineOverlaps(value);
    const activitiesInRow = this.timelineOverlapService.getActivitiesInRow(
      activitiesWithOverlap,
    );
    const determinedActivities =
      this.mapToDeterminedShiftActivities(activitiesInRow);
    return determinedActivities;
  }

  private getActivitiesForShiftIds(
    mapOfShiftAndDeterminedActivities: Record<ShiftId, ActivityRow[]>,
    shiftIds: ShiftId[],
  ): Record<ShiftId, ActivityRow[]> {
    return shiftIds.reduce((acc: Record<ShiftId, ActivityRow[]>, shiftId) => {
      if (mapOfShiftAndDeterminedActivities[shiftId]) {
        acc[shiftId] = mapOfShiftAndDeterminedActivities[shiftId];
      }
      return acc;
    }, {});
  }

  private mapActivitiesToShift<T extends Activity>(activities: T[]) {
    const items = activities.reduce((acc, activity) => {
      const shiftId = activity.shiftId;
      if (shiftId) {
        if (!acc[shiftId]) {
          acc[shiftId] = [];
        }
        acc[shiftId].push(activity);
      }
      return acc;
    }, {} as Record<ShiftId, T[]>);
    return items;
  }

  private mapToDeterminedShiftActivities(
    rows: ActivityWithOverlapLevel[],
  ): ActivityRow[] {
    const result: ActivityRow[] = [];

    rows.forEach((row) => {
      const groupedActivities: ActivityRow = {};

      row.forEach((activity) => {
        // eslint-disable-next-line
        const { overlapLevel, ...cleanedActivity } = activity;
        const activityId = cleanedActivity.id;

        if (activityId) {
          if (!groupedActivities[activityId]) {
            groupedActivities[activityId] =
              cleanedActivity as ActivityWithCategoryAndLocationsAndReportedDetails;
          }
        }
      });

      result.push(groupedActivities);
    });

    return result;
  }
}
