import { Activity } from '@wilson/interfaces';
import { isAfter, isBefore } from 'date-fns';

export function isShiftInPast(activities: Activity[]): boolean {
  if (!activities || activities.length === 0) return false;

  const latestActivity = activities.reduce((latest, current) => {
    const currentEndDatetime = current.endDatetime
      ? new Date(current.endDatetime)
      : null;
    const latestEndDatetime = latest?.endDatetime
      ? new Date(latest.endDatetime)
      : null;

    if (!currentEndDatetime) return latest;
    if (!latestEndDatetime || isAfter(currentEndDatetime, latestEndDatetime)) {
      return current;
    }

    return latest;
  }, null as Activity | null);

  if (!latestActivity || !latestActivity.endDatetime) return false;

  return isBefore(new Date(latestActivity.endDatetime), Date.now());
}
