import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  EventEmitter,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { RoleAction, RolePermissionSubject, Shift } from '@wilson/interfaces';
import { TranslateModule } from '@ngx-translate/core';
import { FeatureFlagsModule } from '@wilson/feature-flags';
import { OpenFindStaffButtonHelperService } from './open-find-staff-button-helper.service';
import { FindStaffButtonComponent } from '../find-staff-button/find-staff-button.component';
import { AuthorizationModule } from '@wilson/authorization';

@Component({
  selector: 'wilson-open-find-staff-button',
  standalone: true,
  imports: [
    CommonModule,
    NzToolTipModule,
    NzButtonModule,
    TranslateModule,
    FeatureFlagsModule,
    FindStaffButtonComponent,
    AuthorizationModule,
  ],
  template: `
    @let hasShiftUpdatePermissions = (RoleAction.Update | ablePure :
    RolePermissionSubject.Shift | async) ;
    <span
      *ngIf="'visual-shift-assignment' | featureFlagPure | async"
      nz-tooltip
      [nzTooltipTitle]="
        !hasShiftUpdatePermissions
          ? ('general.forbidden_action' | translate)
          : canOpenModal
          ? ''
          : ('shift.assignment.cannot_unassign_already_started_or_submitted'
            | translate)
      "
      nzTooltipPlacement="bottom"
      nzTooltipOverlayClassName="dark"
    >
      <wilson-find-staff-button
        [isDisabled]="!canOpenModal || !hasShiftUpdatePermissions"
        (openPopup)="clicked.emit()"
      ></wilson-find-staff-button>
    </span>
  `,
  styleUrls: ['./open-find-staff-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpenFinalStaffButtonComponent implements OnChanges {
  @Input()
  shift!: Shift;

  @Input()
  isDisabled!: boolean;

  @Output()
  clicked = new EventEmitter();

  canOpenModal = false;
  protected RoleAction = RoleAction;
  protected RolePermissionSubject = RolePermissionSubject;
  ngOnChanges(changes: SimpleChanges) {
    if (changes['shift'] || changes['isDisabled']) {
      this.canOpenModal =
        !this.isDisabled && this.helperService.getCanAssignShift(this.shift);
    }
  }

  constructor(private helperService: OpenFindStaffButtonHelperService) {}
}
