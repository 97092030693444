import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';
import { DeleteDialogueComponent } from './delete-dialogue.component';

export interface DeletePopUpParams {
  title: string;
  message: string;
  twoStep: boolean;
  objectToDeleteName: string;
  deleteButtonText?: string;
  options?: {
    multiDelete?: boolean;
    customTexts?: boolean;
    alertMessage?: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class DeleteDialogeHelperService {
  private nzModalService = inject(NzModalService);
  private translate = inject(TranslateService);

  public openDeletePopUp(params: DeletePopUpParams): Observable<boolean> {
    const modalRef: NzModalRef =
      this.nzModalService.create<DeleteDialogueComponent>({
        nzContent: DeleteDialogueComponent,
        nzTitle: params.title,
        nzWidth: 600,
        nzClosable: true,
        nzCentered: true,
        nzBodyStyle: params.twoStep
          ? {
              padding: '0 8px 10px 8px',
            }
          : {},
        nzFooter: [
          {
            label: this.translate.instant('general.cancel'),
            type: 'default',
            onClick: (): void => {
              modalRef.destroy(false);
            },
          },
          {
            label: params.deleteButtonText
              ? params.deleteButtonText
              : this.translate.instant('general.delete'),
            type: 'primary',
            danger: true,
            disabled: (): boolean => {
              return params.twoStep && !modalRef.componentInstance.isTextValid;
            },
            onClick: (): void => {
              modalRef.destroy(true);
            },
          },
        ],
      });

    if (modalRef.componentInstance) {
      modalRef.componentInstance.message = params.message;
      modalRef.componentInstance.alertMessage = params.options?.alertMessage;
      modalRef.componentInstance.twoStep = params.twoStep;
      modalRef.componentInstance.objectToDelete = params.objectToDeleteName;
      modalRef.componentInstance.multiDelete = params.options?.multiDelete;
      modalRef.componentInstance.customTexts = params.options?.customTexts;
    }

    return modalRef.afterClose;
  }
}
