import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { Activity } from '@wilson/interfaces';
import { ActivityCommentsService } from '../../activity-comments.service';

@Component({
  selector: 'wilson-activity-comments-prompt',
  templateUrl: './activity-comments-prompt.component.html',
  styleUrls: ['./activity-comments-prompt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityCommentsPromptComponent {
  // this is only temporary till we update all other areas using this component and then set it to false
  @Input() hasUpdatePermissions = true;
  @Input() activityId: Activity['id'];
  @Output() messageSent = new EventEmitter<string>();
  text = '';

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly commentService: ActivityCommentsService,
    private readonly store: Store,
  ) {}

  async send() {
    if (this.text === '' || !this.activityId || !this.hasUpdatePermissions)
      return;
    await this.commentService.create({
      activityId: this.activityId,
      comment: this.text,
      senderId: this.store.selectSnapshot((state) => state.auth.userId),
      seenByIds: [],
    });
    this.messageSent.emit(this.text);
    this.text = '';
    this.cdr.markForCheck();
  }
}
