import { CommonModule } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  CoveredFoodExpenseEnum,
  RoleAction,
  RolePermissionSubject,
  Shift,
} from '@wilson/interfaces';
import { ShiftsService } from '@wilson/shifts';
import { NzMessageService } from 'ng-zorro-antd/message';
import { firstValueFrom } from 'rxjs';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { FormsModule } from '@angular/forms';
import { FeatureFlagsModule } from '@wilson/feature-flags';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { AuthorizationModule } from '@wilson/authorization';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@Component({
  selector: 'wilson-shift-wage-data',
  templateUrl: './shift-wage-data.component.html',
  styleUrls: ['./shift-wage-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NzSwitchModule,
    FormsModule,
    FeatureFlagsModule,
    CommonModule,
    TranslateModule,
    NzSelectModule,
    AuthorizationModule,
    NzToolTipModule,
  ],
})
export class ShiftWageDataComponent {
  private shiftId: string;
  public hasShiftBonus = false;
  public traineeOnBoard = false;
  public coveredFoodExpenses = [];
  public prevCoveredFoodExpenses = [];
  public excludeFromValidationCalculation = false;
  public CoveredFoodExpenseEnum = CoveredFoodExpenseEnum;

  protected RoleAction = RoleAction;
  protected RolePermissionSubject = RolePermissionSubject;
  @Input() areInputsDisabled = false;
  @Input() set shift(value: Shift | undefined) {
    if (value) {
      this.shiftId = value.id;
      this.hasShiftBonus =
        value.payrollInformation?.spontaneousShiftBonus || false;
      this.excludeFromValidationCalculation =
        value.excludeInValidation || false;
      this.traineeOnBoard = value.payrollInformation?.traineeOnBoard || false;
      this.coveredFoodExpenses = this.prevCoveredFoodExpenses =
        value.payrollInformation?.coveredFoodExpenses || [];
    }
  }

  constructor(
    private readonly shiftsService: ShiftsService,
    private nzMessageService: NzMessageService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private shiftGateway: ShiftsService,
  ) {}

  public async changeSpontaneousShiftBonus(
    spontaneousShiftBonus: boolean,
  ): Promise<void> {
    try {
      await firstValueFrom(
        this.shiftsService.saveShiftPayrollInformation(this.shiftId, {
          spontaneousShiftBonus,
        }),
      );
    } catch {
      this.nzMessageService.error(this.translate.instant('general.error'));
      this.hasShiftBonus = !spontaneousShiftBonus;
      this.cdr.detectChanges();
    }
  }

  public async updateCalculationExclusion(exclude: boolean): Promise<void> {
    this.excludeFromValidationCalculation = exclude;
    try {
      await firstValueFrom(
        this.shiftGateway.updateShift({
          id: this.shiftId,
          excludeInValidation: exclude,
        } as Shift),
      );
    } catch {
      this.nzMessageService.error(this.translate.instant('general.error'));
      this.hasShiftBonus = !exclude;
      this.cdr.detectChanges();
    }
  }

  public async changeTraineeOnBoard(traineeOnBoard: boolean): Promise<void> {
    try {
      await firstValueFrom(
        this.shiftsService.saveShiftPayrollInformation(this.shiftId, {
          traineeOnBoard,
        }),
      );
    } catch {
      this.nzMessageService.error(this.translate.instant('general.error'));
      this.traineeOnBoard = !traineeOnBoard;
      this.cdr.detectChanges();
    }
  }

  public async setCoveredFoodExpenses(
    coveredFoodExpenses: CoveredFoodExpenseEnum[],
  ): Promise<void> {
    try {
      await firstValueFrom(
        this.shiftsService.saveShiftPayrollInformation(this.shiftId, {
          coveredFoodExpenses,
        }),
      );
      this.prevCoveredFoodExpenses = coveredFoodExpenses;
    } catch {
      this.nzMessageService.error(this.translate.instant('general.error'));
      this.coveredFoodExpenses = this.prevCoveredFoodExpenses;
      this.cdr.detectChanges();
    }
  }
}
