import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Absence,
  Shift,
  TimelineShift,
  WithPreparedAttributes,
  ZOOM_LEVEL,
} from '@wilson/interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { WilsonState } from '@wilson/non-domain-specific/decorators/wilson-state';
export interface HorizontalDemarcatorRange {
  left: number | null;
  right: number | null;
  shiftDuration: number | null;
  adjustmentNeeded: boolean;
}

const SnappingOnSteps = {
  [ZOOM_LEVEL.MONTH]: 60,
  [ZOOM_LEVEL.HALF_MONTH]: 60,
  [ZOOM_LEVEL.DEFAULT]: 60,
  [ZOOM_LEVEL.HALF_WEEK]: 30,
  [ZOOM_LEVEL.DAY]: 15,
};

const SnappingOffSteps = {
  [ZOOM_LEVEL.MONTH]: 30,
  [ZOOM_LEVEL.HALF_MONTH]: 30,
  [ZOOM_LEVEL.DEFAULT]: 30,
  [ZOOM_LEVEL.HALF_WEEK]: 15,
  [ZOOM_LEVEL.DAY]: 1,
};

export const SELECTED_REGION = 'selectedRegion';
export const DEFAULT_ACTIVITY_SNAPPING_MINUTES = 5;

export enum TabEnum {
  UnassignedShifts,
  UnassignedActivities,
}

@Injectable()
export class DragAndDropModeService {
  static readonly SHADOW_SHIFT_ALLOWANCE_PIXEL = 7;
  private assignmentModeSubject = new BehaviorSubject(true);
  private moveModeSubject = new BehaviorSubject(false);
  private snappingModeSubject = new BehaviorSubject(true);
  private showTimeDifferenceSubject = new BehaviorSubject(true);
  private isMovingItemLeftRightSubject = new BehaviorSubject(false);
  private showShiftDropZoneSubject = new BehaviorSubject(true);
  private horizontalDemarcatorRangeSubject =
    new BehaviorSubject<HorizontalDemarcatorRange>({
      left: 0,
      right: 0,
      shiftDuration: 0,
      adjustmentNeeded: true,
    });
  private showDurationSubject = new BehaviorSubject<boolean>(false);
  private isTimeIndicatorsVisibleSubject = new BehaviorSubject(true);
  private snappingStepSizeSubject = new BehaviorSubject(30);
  private shouldDisplayMovingDemarcatorLineSubject = new BehaviorSubject(false);
  private shouldDisplayAbsenceDropZone = new BehaviorSubject(false);
  private draggingItemIdSubject = new BehaviorSubject<string | null>(null);
  private showActivityDropZoneSubject = new BehaviorSubject(false);
  private showMoveActivityDropZoneSubject = new BehaviorSubject(false);
  private draggingShiftV2Subject = new BehaviorSubject<TimelineShift | null>(
    null,
  );
  private draggingAbsenceSubject = new BehaviorSubject<
    | (Absence & {
        id: string;
        isHighlighted: boolean;
      })
    | null
  >(null);

  public isAssignmentMode$ = this.assignmentModeSubject.asObservable();
  public isMoveMode$ = this.moveModeSubject.asObservable();
  public isSnapping$ = this.snappingModeSubject.asObservable();
  public showTimeDifference$ = this.showTimeDifferenceSubject.asObservable();
  public showShiftDropZone$ = this.showShiftDropZoneSubject.asObservable();
  public isMovingItemLeftRight$ =
    this.isMovingItemLeftRightSubject.asObservable();
  public horizontalDemarcatorRange$ =
    this.horizontalDemarcatorRangeSubject.asObservable();
  public isTimeIndicatorsVisible$ =
    this.isTimeIndicatorsVisibleSubject.asObservable();
  public snappingStepSize$ = this.snappingStepSizeSubject.asObservable();
  public shouldDisplayMovingDemarcatorLine$ =
    this.shouldDisplayMovingDemarcatorLineSubject.asObservable();
  public shouldDisplayAbsenceDropZone$ =
    this.shouldDisplayAbsenceDropZone.asObservable();
  public draggingItemId$ = this.draggingItemIdSubject.asObservable();
  public showActivityDropZone$ =
    this.showActivityDropZoneSubject.asObservable();
  public showMoveActivityDropZone$ =
    this.showMoveActivityDropZoneSubject.asObservable();
  public showDuration$ = this.showDurationSubject.asObservable();
  public draggingShiftV2$ = this.draggingShiftV2Subject.asObservable();
  public draggingAbsence$ = this.draggingAbsenceSubject.asObservable();

  @WilsonState<Date | null>(null)
  private draggingItemStartDatetime!: Date | null;
  public draggingItemStartDatetime$!: Observable<Date | null>;

  @WilsonState<Date | null>(null)
  private draggingItemEndDatetime!: Date | null;
  public draggingItemEndDatetime$!: Observable<Date | null>;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) {}

  setDraggingItemStartDatetime(startDatetime: Date | null): void {
    this.draggingItemStartDatetime = startDatetime;
  }

  setDraggingItemEndDatetime(endDatetime: Date | null): void {
    this.draggingItemEndDatetime = endDatetime;
  }

  toggleAssignmentMode(): void {
    this.assignmentModeSubject.next(true);
    this.moveModeSubject.next(false);
    this.shouldDisplayMovingDemarcatorLineSubject.next(false);
  }

  toggleMoveMode(): void {
    this.moveModeSubject.next(true);
    this.assignmentModeSubject.next(false);
    this.shouldDisplayMovingDemarcatorLineSubject.next(true);
  }

  setSnappingStepSize(zoomLevel: ZOOM_LEVEL): void {
    if (this.snappingModeSubject.value) {
      this.snappingStepSizeSubject.next(SnappingOnSteps[zoomLevel]);
    } else {
      this.snappingStepSizeSubject.next(SnappingOffSteps[zoomLevel]);
    }
  }

  setCustomSnappingStepSize(stepSize: number): void {
    this.snappingStepSizeSubject.next(stepSize);
  }

  toggleSnapping(zoomLevel: ZOOM_LEVEL): void {
    this.snappingModeSubject.next(!this.snappingModeSubject.value);
    this.setSnappingStepSize(zoomLevel);
  }

  setTimeDifferenceVisible(isVisible: boolean): void {
    this.showTimeDifferenceSubject.next(isVisible);
  }

  setShiftDropZoneVisible(isVisible: boolean): void {
    this.showShiftDropZoneSubject.next(isVisible);
  }

  setIsMovingItemLeftRight(value: boolean): void {
    this.isMovingItemLeftRightSubject.next(value);
  }

  setHorizontalDemarcatorRange(range: HorizontalDemarcatorRange): void {
    this.horizontalDemarcatorRangeSubject.next(range);
  }

  setDisplayTimeVisible(isVisible: boolean): void {
    this.isTimeIndicatorsVisibleSubject.next(isVisible);
  }

  setDisplayMovingDemarcatorLine(isDisplayed: boolean): void {
    this.shouldDisplayMovingDemarcatorLineSubject.next(isDisplayed);
  }

  setAbsenceDropZone(isAbsence: boolean): void {
    this.shouldDisplayAbsenceDropZone.next(isAbsence);
  }

  setDraggingItemId(id: string | null): void {
    this.draggingItemIdSubject.next(id);
  }

  setUnassignedRegionInUrl(userSelectedTab: number): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { [SELECTED_REGION]: userSelectedTab },
      queryParamsHandling: 'merge',
    });
  }

  setActivityDropZone(showDropZone: boolean): void {
    this.showActivityDropZoneSubject.next(showDropZone);
  }

  setMoveActivityDropZone(showDropZone: boolean): void {
    this.showMoveActivityDropZoneSubject.next(showDropZone);
  }

  resetMoveModeSettings(zoomLevel: ZOOM_LEVEL): void {
    this.setDraggingItemId(null);
    this.setDisplayMovingDemarcatorLine(false);
    this.setDisplayTimeVisible(false);
    this.setIsMovingItemLeftRight(false);
    this.setSnappingStepSize(zoomLevel);
  }

  toggleDurationOnDemarcatorLine(showDuration: boolean): void {
    this.showDurationSubject.next(showDuration);
  }

  updateDemarcatorSettings(
    currentDemarcatorRange: HorizontalDemarcatorRange,
  ): void {
    this.setDisplayMovingDemarcatorLine(true);
    this.setHorizontalDemarcatorRange(currentDemarcatorRange);
  }

  setDraggingShiftV2(shift: TimelineShift | null): void {
    this.draggingShiftV2Subject.next(shift);
  }

  setDraggingAbsence(
    absence:
      | (Absence & {
          id: string;
          isHighlighted: boolean;
        })
      | null,
  ): void {
    this.draggingAbsenceSubject.next(absence);
  }
}
