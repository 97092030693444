import { Injectable } from '@angular/core';
import {
  Activity,
  OperationStatus,
  PublicationStatus,
  Shift,
  ShiftState,
  ShiftTemplate,
  ShiftTemplateActivityTemplates,
} from '@wilson/interfaces';
import { WilsonApp } from '@wilson/interfaces';
import { DateTimeFormat } from '@wilson/utils';
import { addDays, format } from 'date-fns';
import { cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ShiftTemplateToShiftService {
  public createShiftFromTemplateAndActivities(
    shiftTemplate: ShiftTemplate,
  ): Shift {
    const shift: Shift = {
      name: shiftTemplate.name,
      organizationalUnitId: shiftTemplate.organizationalUnitId,
      organizationalUnit: shiftTemplate.organizationalUnit,
      comment: null,
      activities: [] as Activity[],
      userId: null,
      shiftCategoryId: shiftTemplate.shiftCategoryId,
      shiftCategory: shiftTemplate.shiftCategory,
      startDate: format(new Date(), DateTimeFormat.DatabaseDateFormat),
      publicationStatus: PublicationStatus.NotPublished,
      state: ShiftState.NotSubmitted,
      projectId: shiftTemplate.projectId,
      labels: shiftTemplate.labels,
    };

    shift.activities = this.sortAndTransformActivitiesTemplateToActivities(
      shiftTemplate.shiftTemplateActivityTemplates || [],
    );

    return shift;
  }

  public sortAndTransformActivitiesTemplateToActivities(
    activityTemplates: ShiftTemplateActivityTemplates[],
  ): Activity[] {
    const shiftActivities: Activity[] = [];
    const sortedActivityTemplates = [...activityTemplates].sort(
      (
        { activityTemplate: activityTemplateA }: ShiftTemplateActivityTemplates,
        { activityTemplate: activityTemplateB }: ShiftTemplateActivityTemplates,
      ) => {
        return (
          new Date(activityTemplateA.startDatetime).getTime() -
          new Date(activityTemplateB.startDatetime).getTime()
        );
      },
    );

    for (const shiftTemplateActivityTemplate of sortedActivityTemplates) {
      shiftActivities.push({
        name: shiftTemplateActivityTemplate.activityTemplate.name,
        startDatetime: format(
          new Date(
            shiftTemplateActivityTemplate.activityTemplate.startDatetime,
          ),
          DateTimeFormat.TimeFormat,
        ),
        endDatetime: format(
          new Date(shiftTemplateActivityTemplate.activityTemplate.endDatetime),
          DateTimeFormat.TimeFormat,
        ),
        activityCategoryId:
          shiftTemplateActivityTemplate.activityTemplate.activityCategoryId,
        activityCategory:
          shiftTemplateActivityTemplate.activityTemplate.activityCategory,
        startLocationId:
          shiftTemplateActivityTemplate.activityTemplate.startLocationId,
        startLocation:
          shiftTemplateActivityTemplate.activityTemplate.startLocation,
        endLocationId:
          shiftTemplateActivityTemplate.activityTemplate.endLocationId,
        endLocation: shiftTemplateActivityTemplate.activityTemplate.endLocation,
        professionId:
          shiftTemplateActivityTemplate.activityTemplate.professionId,
        profession: shiftTemplateActivityTemplate.activityTemplate.profession,
        shiftId: null,
        serviceId: null,
        operationalStatus: OperationStatus.NotStarted,
        createdFrom: WilsonApp.Portal,
        viaLocationIds: [],
        jobId: null,
      } as Activity);
    }
    return shiftActivities;
  }

  public setStartAndEndTimeForEachActivity(
    activities: Activity[],
    selectedDate: Date,
  ) {
    const clonedActivities = cloneDeep(activities);

    clonedActivities.forEach((activity, i) => {
      if (activity.startDatetime.length === 5) {
        activity.name = activity.name?.trim() || '';

        if (
          i !== 0 &&
          format(
            new Date(clonedActivities[i - 1].endDatetime),
            DateTimeFormat.TimeFormat,
          ) > activity.startDatetime
        ) {
          activity.startDatetime = new Date(
            format(
              addDays(new Date(clonedActivities[i - 1].endDatetime), 1),
              DateTimeFormat.DatabaseDateFormat,
            ) +
              ' ' +
              activity.startDatetime,
          ).toISOString();
        } else {
          activity.startDatetime = new Date(
            (i === 0
              ? format(
                  new Date(selectedDate),
                  DateTimeFormat.DatabaseDateFormat,
                )
              : format(
                  new Date(clonedActivities[i - 1].endDatetime),
                  DateTimeFormat.DatabaseDateFormat,
                )) +
              ' ' +
              activity.startDatetime,
          ).toISOString();
        }
        if (
          format(new Date(activity.startDatetime), DateTimeFormat.TimeFormat) >
          activity.endDatetime
        ) {
          activity.endDatetime = new Date(
            format(
              addDays(new Date(activity.startDatetime), 1),
              DateTimeFormat.DatabaseDateFormat,
            ) +
              ' ' +
              activity.endDatetime,
          ).toISOString();
        } else {
          activity.endDatetime = new Date(
            format(
              new Date(activity.startDatetime),
              DateTimeFormat.DatabaseDateFormat,
            ) +
              ' ' +
              activity.endDatetime,
          ).toISOString();
        }
      }
    });

    return clonedActivities;
  }

  public createShiftFromTemplateWithSelectedDate(
    shiftTemplate: ShiftTemplate,
    selectedDate: Date,
  ): Shift & {
    activities: Activity[];
  } {
    const materializedShiftWithActivityHours =
      this.createShiftFromTemplateAndActivities(shiftTemplate);

    const materializedActivitiesBaseOnSelectedDate =
      this.setStartAndEndTimeForEachActivity(
        materializedShiftWithActivityHours.activities,
        selectedDate,
      );

    return {
      ...materializedShiftWithActivityHours,
      activities: materializedActivitiesBaseOnSelectedDate
        ? materializedActivitiesBaseOnSelectedDate
        : null,
    };
  }
}
