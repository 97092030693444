<button
  nz-button
  nz-tooltip
  [nzTooltipTitle]="showWarning && warningText ? warningText : ''"
  [nzType]="type"
  [disabled]="isDisabled"
  (click)="openPopup.emit()"
  class="d-flex justify-content-center align-items-center"
  [class.ant-btn-default]="type === FindStaffButtonType.Default"
  data-test-id="wilson-find-staff-button"
>
  <fa-icon [icon]="faMagic" class="me-2"></fa-icon>&nbsp;
  <span>
    {{ title ? title : ('shift.assignment.check-alternative' | translate) }}
  </span>
  @if (showWarning) {
  <i class="fas fa-exclamation-triangle text-secondary ms-2"></i>
  }
</button>
