import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import {
  PartialShiftSeries,
  ShiftSeries,
  ShiftSeriesWithLabelAndProject,
} from '@wilson/interfaces';
import { firstValueFrom, Observable } from 'rxjs';

@Injectable()
export class ShiftSeriesService {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(ConfigService)
    private readonly config: ConfigOptions,
  ) {}

  public createShiftSeries(shiftSeries: ShiftSeries[]): Promise<ShiftSeries[]> {
    return firstValueFrom(
      this.httpClient.post<ShiftSeries[]>(`${this.config.host}/shift-series`, {
        items: shiftSeries,
      }),
    );
  }

  public getResolveShiftSeriesByDate(
    validFrom: string,
    validUntil?: string | Date,
  ): Observable<ShiftSeries[]> {
    const lookup: { validUntil?: string | Date; validFrom?: string } = {
      validFrom,
    };
    if (validUntil) {
      lookup.validUntil = validUntil;
    }

    return this.httpClient.post<ShiftSeries[]>(
      `${this.config.host}/shift-series/resolved`,
      lookup,
    );
  }

  public getResolvedShiftSeries(
    shiftSeriesId: string,
  ): Observable<ShiftSeries[]> {
    return this.httpClient.post<ShiftSeries[]>(
      `${this.config.host}/shift-series/resolved`,
      { id: shiftSeriesId },
    );
  }

  getShiftSeries(shiftSeriesId: string): Observable<ShiftSeries> {
    return this.httpClient.get<ShiftSeries>(
      `${this.config.host}/shift-series/${shiftSeriesId}`,
    );
  }

  public deleteShiftSeries(shiftSeriesId: string) {
    return firstValueFrom(
      this.httpClient.delete(
        `${this.config.host}/shift-series/${shiftSeriesId}`,
      ),
    );
  }

  public updateShiftSeries(
    shiftSeries: Partial<ShiftSeriesWithLabelAndProject>,
  ): Promise<ShiftSeries[]> {
    return firstValueFrom(
      this.httpClient.patch<ShiftSeries[]>(
        `${this.config.host}/shift-series/${shiftSeries.id}`,
        shiftSeries,
      ),
    );
  }

  public updatePartialShiftSeries(
    shiftSeries: PartialShiftSeries,
  ): Promise<ShiftSeries[]> {
    return firstValueFrom(
      this.httpClient.patch<ShiftSeries[]>(
        `${this.config.host}/shift-series/${shiftSeries.id}/edit-shifts`,
        shiftSeries,
      ),
    );
  }

  getShiftSeriesExport(shiftSeriesId: string) {
    return this.httpClient.get<File>(
      `${this.config.host}/shift-series/${shiftSeriesId}/pdf-export`,
      {
        responseType: 'blob' as 'json',
      },
    );
  }
}
