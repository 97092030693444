<div class="menu-header-wrapper">
  <div
    nz-tooltip
    [nzTooltipTitle]="params.displayName"
    class="custom-header"
    (click)="onHeaderClick()"
  >
    <span
      *ngIf="params.displayName"
      class="custom-header-label ag-header-cell-text"
      >{{ params.displayName }}</span
    >
    @if(params.enableSorting) {
    <span *ngIf="sort === 'asc'" class="ag-icon ag-icon-asc"></span>
    <span *ngIf="sort === 'desc'" class="ag-icon ag-icon-desc"></span>
    }
  </div>
  <i (click)="openColumnsModal()" class="fas fa-ellipsis-v dropdown-icon"></i>
</div>
