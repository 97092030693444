/* eslint-disable @nx/enforce-module-boundaries */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AgGridSharedModule } from '@wilson/ag-grid-shared';
import { RouteModule } from '@wilson/route';
import { LoadingSpinnerModule } from '@wilson/shared-components';
import { TooltipModule } from '@wilson/tooltip';
import { AgGridModule } from 'ag-grid-angular';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { ActivityQualificationsModalComponent } from '../components/activity-qualifications/activity-qualifications-modal/activity-qualifications-modal.component';
import { ActivityQualificationsComponent } from '../components/activity-qualifications/activity-qualifications.component';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { AuthorizationModule } from '@wilson/authorization';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouteModule,
    TranslateModule,
    AgGridModule,
    AgGridSharedModule,
    NzPopoverModule,
    NzToolTipModule,
    TooltipModule,
    NzSelectModule,
    LoadingSpinnerModule,
    AuthorizationModule,
  ],
  declarations: [
    ActivityQualificationsComponent,
    ActivityQualificationsModalComponent,
  ],
  exports: [
    ActivityQualificationsComponent,
    ActivityQualificationsModalComponent,
  ],
})
export class ActivityQualificationsModule {}
