<div *ngIf="!twoStep">
  <div class="card-body">
    <div class="card-text text-dark">
      {{ message }}
    </div>
  </div>
</div>

<div *ngIf="twoStep">
  <div class="card-body">
    <div class="row alert-msg pr-5 py-2">
      <div class="col-11 ms-2">
        <p class="my-2" *ngIf="!customTexts">
          {{
            (multiDelete
              ? 'modalDelete.alertHead.multipleDelete'
              : 'modalDelete.alertHead'
            ) | translate
          }}
        </p>
        <p class="my-2" *ngIf="customTexts">{{ alertMessage }}</p>
      </div>
    </div>
    <div class="row my-3 ps-1">
      <p class="mb-0" [innerHTML]="message"></p>
      <p class="mb-0" *ngIf="!customTexts">
        {{
          (multiDelete
            ? 'modalDelete.confirmText.multipleDelete'
            : 'modalDelete.confirmText'
          ) | translate
        }}
      </p>
      <p class="mb-0" *ngIf="!customTexts">
        {{
          (multiDelete
            ? 'modalDelete.objectName.multipleDelete'
            : 'modalDelete.objectName'
          ) | translate
        }}
        <b>{{ objectToDelete }}</b>
      </p>
    </div>
    <form [formGroup]="deleteForm" class="row pt-0 ps-3">
      <input
        maxlength="100"
        type="text"
        class="form-control col"
        [placeholder]="objectToDelete"
        (keyup)="checkInput()"
        formControlName="object"
        id="object-input"
        [ngClass]="{
          'invalid-input':
            deleteForm.get('object').touched && deleteForm.get('object').errors
        }"
      />
      <div class="col">
        <div
          class="d-flex mt-1"
          *ngIf="
            deleteForm.get('object').touched &&
            deleteForm.get('object').errors?.required
          "
        >
          <i class="fas fa-info-circle text-danger me-2 mt-2"></i
          ><span class="text-danger mt-1">{{
            'modalDelete.required' | translate
          }}</span>
        </div>
      </div>
    </form>
  </div>
</div>
