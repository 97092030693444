import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivityCategoryAndNameComponent } from './components/activity-category-and-name.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [ActivityCategoryAndNameComponent],
  exports: [ActivityCategoryAndNameComponent],
})
export class ActivitiesAgGridModule {}
