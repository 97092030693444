import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams, SortDirection } from 'ag-grid-community';
import { AgGridCustomMenuHeaderService } from './ag-grid-custom-menu-header.service';
import { Subscription } from 'rxjs';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzModalService } from 'ng-zorro-antd/modal';

import { TranslateService } from '@ngx-translate/core';
import {
  AgGridColumnOptionModalComponent,
  WilsonAgGridColumnOptionModalInput,
} from '../ag-grid-column-option-modal/ag-grid-column-option-modal.component';

@Component({
  selector: 'wilson-ag-grid-custom-menu-header',
  standalone: true,
  imports: [CommonModule, NzToolTipModule],
  templateUrl: './ag-grid-custom-menu-header.component.html',
  styleUrl: './ag-grid-custom-menu-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgGridCustomMenuHeaderComponent
  implements IHeaderAngularComp, OnDestroy
{
  public params!: IHeaderParams;
  protected displayName!: string;
  public sort: 'asc' | 'desc' | null = null;

  private subs = new Subscription();

  constructor(
    private agGridCustomMenuHeaderService: AgGridCustomMenuHeaderService,
    private cdr: ChangeDetectorRef,
    private modalService: NzModalService,
    private translateService: TranslateService,
  ) {}

  agInit(params: IHeaderParams): void {
    this.refresh(params);
    this.subs = this.agGridCustomMenuHeaderService.refresh$.subscribe(
      ({ sourceColumnId }) => {
        // We don't update ourselves again
        if (this.params.column.getColId() !== sourceColumnId) {
          this.refresh(params);
          this.cdr.detectChanges();
        }
      },
    );
  }

  refresh(params: IHeaderParams): boolean {
    this.params = params;
    this.displayName = params.displayName;
    this.sort = params.column.getSort() || null;
    return true;
  }

  onHeaderClick(): void {
    if (this.params.enableSorting) {
      this.sort = this.getNextSortDirection(this.sort);
      this.updateSortDirectionForAllColumns(this.sort);
      this.params.api.onSortChanged();
    }
  }

  openColumnsModal(): void {
    const data: WilsonAgGridColumnOptionModalInput = {
      ...(this.params.column.getUserProvidedColDef()
        ?.headerComponentParams as Omit<
        WilsonAgGridColumnOptionModalInput,
        'gridApi'
      >),
      gridApi: this.params.api,
    };
    if (data) {
      this.modalService.create<
        AgGridColumnOptionModalComponent,
        WilsonAgGridColumnOptionModalInput
      >({
        nzContent: AgGridColumnOptionModalComponent,
        nzData: data,
        nzClosable: true,
        nzCentered: true,
        nzFooter: null,
        nzTitle: this.translateService.instant('general.table_column'),
      });
    }
  }

  private updateSortDirectionForAllColumns(
    newSortDirection: SortDirection,
  ): void {
    const state = {
      state: this.params.api.getColumns()?.map((column) => {
        return {
          colId: column.getColId(),
          sort:
            column.getColId() === this.params.column.getColId()
              ? newSortDirection
              : null, // Set sort for this column and reset for others
        };
      }),
      applyOrder: false,
    };

    this.params.api.applyColumnState(state);
    this.agGridCustomMenuHeaderService.trigggerUpdate(
      this.params.column.getColId(),
    );
  }

  private getNextSortDirection(
    currentSortDirection: SortDirection,
  ): SortDirection {
    if (currentSortDirection === 'asc') {
      return 'desc';
    } else if (this.sort === 'desc') {
      return null;
    } else {
      return 'asc';
    }
  }

  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }
}
