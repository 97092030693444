import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AuthorizationModule } from '@wilson/authorization';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { RoleAction, RolePermissionSubject } from '@wilson/interfaces';

@Component({
  selector: 'wilson-necessary-stay-toggle',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    NzSwitchModule,
    NzToolTipModule,
    AuthorizationModule,
  ],
  templateUrl: './necessary-stay-toggle.component.html',
  styleUrl: './necessary-stay-toggle.component.scss',
})
export class NecessaryStayToggleComponent {
  @Input()
  currentShiftNecessaryStay = false;

  @Input()
  boldText = false;

  @Input()
  hasNecessaryStayUpdatePermissions = false;

  @Output()
  updatedNecessaryStay = new EventEmitter();

  RoleAction = RoleAction;
  RolePermissionSubject = RolePermissionSubject;
}
