import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FeatureFlagsModule } from '@wilson/feature-flags';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { ShiftWageDataComponent } from '../../shift-wage-data/shift-wage-data.component';
import { RoleAction, RolePermissionSubject, Shift } from '@wilson/interfaces';
import { AuthorizationModule } from '@wilson/authorization';

@Component({
  selector: 'wilson-collapsible-shift-wage-data',
  standalone: true,
  imports: [
    CommonModule,
    NzCollapseModule,
    FeatureFlagsModule,
    TranslateModule,
    ShiftWageDataComponent,
    AuthorizationModule,
  ],
  templateUrl: './collapsible-shift-wage-data.component.html',
  styleUrl: './collapsible-shift-wage-data.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapsibleShiftWageDataComponent {
  @Input() shift!: Shift | null;
  @Input() isExpandedOnOpen = false;
  @Input() areInputsDisabled = false;
  protected RoleAction = RoleAction;
  protected RolePermissionSubject = RolePermissionSubject;
}
