<div *ngIf="'timeline-payroll-notes' | featureFlagPure | async">
  <nz-collapse [nzBordered]="false" nzGhost>
    <nz-collapse-panel
      #wageDataPanel
      [nzHeader]="shiftNotesSectionHeader"
      [nzActive]="isExpandedOnOpen"
      [nzShowArrow]="false"
      class="collapse-panel"
      [nzExtra]="shiftNotesCollapseIcon"
    >
      <wilson-shift-notes
        [shiftId]="shiftId"
        (refresh)="refresh.emit()"
      ></wilson-shift-notes>
    </nz-collapse-panel>
  </nz-collapse>

  <ng-template #shiftNotesSectionHeader>
    <span class="header-text">
      {{ 'shift_drawer.payroll_notes' | translate }}
    </span>
  </ng-template>

  <ng-template #shiftNotesCollapseIcon>
    <i
      class="fas fa-chevron-up"
      [ngClass]="wageDataPanel.nzActive ? 'rotate-180' : 'rotate-0'"
    ></i>
  </ng-template>

  <nz-divider class="my-3"></nz-divider>
</div>
