import {
  Component,
  ChangeDetectionStrategy,
  Input,
  inject,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'wilson-delete-dialogue',
  templateUrl: './delete-dialogue.component.html',
  styleUrls: ['./delete-dialogue.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteDialogueComponent {
  @Input() title!: string;
  @Input() message!: string;
  @Input() alertMessage!: string;
  @Input() deleteButtonText!: string;
  @Input() twoStep!: boolean;
  @Input() objectToDelete!: string;
  @Input() multiDelete!: boolean;
  @Input() customTexts!: boolean;

  protected formBuilder = inject(FormBuilder);
  protected deleteForm = this.formBuilder.group({
    object: ['', [Validators.required]],
  });
  protected isTextValid = false;
  protected loading = false;

  protected checkInput(): void {
    // Remove all white spaces from the input value and the object to delete
    // This is done due to the fact that we have one qualification with a space in the name (masterdata.qualification.eec5619c-7e6d-4ed2-93f2-0d92c7119d0b)
    const inputValue = this.deleteForm
      .get('object')
      ?.value?.replace(/\s+/g, '');
    const objectToDelete = this.objectToDelete.replace(/\s+/g, '');
    if (inputValue === objectToDelete) {
      this.isTextValid = true;
    } else {
      this.isTextValid = false;
    }
  }
}
