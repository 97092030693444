import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AgGridCustomMenuHeaderService {
  private refreshSubject = new Subject<{
    sourceColumnId: string;
  }>();

  refresh$ = this.refreshSubject.asObservable();

  trigggerUpdate(sourceColumnId: string): void {
    this.refreshSubject.next({ sourceColumnId });
  }
}
