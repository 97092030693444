import { FormArray, FormControl, FormGroup } from '@angular/forms';
import {
  CalculationPercentage,
  CalculationPercentageForm,
  ExportParameterUnitName,
} from './payrollCategory';

export enum ActivityCategoryPayrollSettingDrawerFormFieldEnum {
  ActivityCategoryId = 'activityCategoryId',
  IsPayableTime = 'isPayableTime',
  IsBonusRelevant = 'isBonusRelevant',
  IsSeparateCategory = 'isSeparateCategory',
  PayableTimePercentages = 'payableTimePercentages',
  BonusRelevantPercentages = 'bonusRelevantPercentages',
  SeparateCategory = 'separateCategory',
  Name = 'name',
  ProviderEditingKey = 'providerEditingKey',
  ExternalCategoryId = 'externalCategoryId',
  Unit = 'unit',
  DefaultCostCenter = 'defaultCostCenter',
}

export interface ActivityCategoryPayrollSettingDrawerFormValue {
  [ActivityCategoryPayrollSettingDrawerFormFieldEnum.ActivityCategoryId]:
    | string
    | null;
  [ActivityCategoryPayrollSettingDrawerFormFieldEnum.IsPayableTime]: boolean;
  [ActivityCategoryPayrollSettingDrawerFormFieldEnum.IsBonusRelevant]: boolean;
  [ActivityCategoryPayrollSettingDrawerFormFieldEnum.IsSeparateCategory]: boolean;
  [ActivityCategoryPayrollSettingDrawerFormFieldEnum.PayableTimePercentages]:
    | CalculationPercentage[]
    | null;
  [ActivityCategoryPayrollSettingDrawerFormFieldEnum.BonusRelevantPercentages]:
    | CalculationPercentage[]
    | null;
  [ActivityCategoryPayrollSettingDrawerFormFieldEnum.SeparateCategory]:
    | CalculationPercentage[]
    | null;
  [ActivityCategoryPayrollSettingDrawerFormFieldEnum.Name]: string | null;
  [ActivityCategoryPayrollSettingDrawerFormFieldEnum.ProviderEditingKey]:
    | number
    | null;
  [ActivityCategoryPayrollSettingDrawerFormFieldEnum.ExternalCategoryId]:
    | number[]
    | null;
  [ActivityCategoryPayrollSettingDrawerFormFieldEnum.Unit]: ExportParameterUnitName | null;
  [ActivityCategoryPayrollSettingDrawerFormFieldEnum.DefaultCostCenter]:
    | number
    | null;
}

export type ActivityCategoryPayrollSettingFormControls = {
  [ActivityCategoryPayrollSettingDrawerFormFieldEnum.ActivityCategoryId]: FormControl<
    string | null
  >;
  [ActivityCategoryPayrollSettingDrawerFormFieldEnum.IsPayableTime]: FormControl<boolean>;
  [ActivityCategoryPayrollSettingDrawerFormFieldEnum.IsBonusRelevant]: FormControl<boolean>;
  [ActivityCategoryPayrollSettingDrawerFormFieldEnum.IsSeparateCategory]: FormControl<boolean>;
  [ActivityCategoryPayrollSettingDrawerFormFieldEnum.PayableTimePercentages]: FormArray<
    FormGroup<CalculationPercentageForm>
  >;
  [ActivityCategoryPayrollSettingDrawerFormFieldEnum.BonusRelevantPercentages]: FormArray<
    FormGroup<CalculationPercentageForm>
  >;
  [ActivityCategoryPayrollSettingDrawerFormFieldEnum.SeparateCategory]: FormArray<
    FormGroup<CalculationPercentageForm>
  >;
  [ActivityCategoryPayrollSettingDrawerFormFieldEnum.Name]: FormControl<
    string | null
  >;
  [ActivityCategoryPayrollSettingDrawerFormFieldEnum.ProviderEditingKey]: FormControl<
    number | null
  >;
  [ActivityCategoryPayrollSettingDrawerFormFieldEnum.ExternalCategoryId]: FormControl<
    number[] | null
  >;
  [ActivityCategoryPayrollSettingDrawerFormFieldEnum.Unit]: FormControl<ExportParameterUnitName | null>;
  [ActivityCategoryPayrollSettingDrawerFormFieldEnum.DefaultCostCenter]: FormControl<
    number | null
  >;
};

export interface CreateActivityCategoryPayrollSetting {
  activityCategoryId: string;
  payrollSettingId: string;
  payableTimePercentages?: CalculationPercentage[];
  bonusRelevantPercentages?: CalculationPercentage[];
}
