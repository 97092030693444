<div
  *ngIf="FeatureName.Billing | featurePure | async"
  nz-tooltip
  [nzTooltipTitle]="agreementTooltip"
  [nzTooltipColor]="'#fff'"
  nzTooltipPlacement="top"
  [nzTooltipOverlayStyle]="{
    'max-width': '250px'
  }"
  [class.cursor-pointer]="isEditable && !isDisabled"
  [class.cursor-not-allowed]="isDisabled"
  (click)="isEditable && !isDisabled && openChangeAgreementModal()"
>
  <div class="d-flex">
    <fa-icon
      [icon]="faFileContract"
      class="text-dark agreement"
      [class.agreement-disabled]="isDisabled"
    ></fa-icon>
    <span
      class="active"
      [class.active--hidden]="isDisabled"
      *ngIf="activity?.agreement"
    ></span>
  </div>
</div>
<ng-template #agreementTooltip>
  <div *ngIf="!isDisabled" class="text-dark fw-bold py-1">
    {{ 'general.agreement' | translate }}
  </div>
  <div class="py-1" *ngIf="!isDisabled">
    <hr class="m-0" />
    <span *ngIf="activity?.agreement">
      {{ activity.agreement.client.name }} /
      {{ activity.agreement.name }}
    </span>
    <span *ngIf="!activity?.agreement">
      {{ 'page.agreement.no_agreement_selected' | translate }}
    </span>
    <span *ngIf="isDisabled && hasPermissions">
      {{ 'page.agreement.disabled_agreement' | translate }}
    </span>
  </div>

  <span *ngIf="isDisabled && hasPermissions">
    {{ 'page.agreement.disabled_agreement' | translate }}
  </span>

  <span *ngIf="isDisabled && !hasPermissions">
    {{ 'general.forbidden_action' | translate }}
  </span>
</ng-template>
