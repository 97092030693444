import { Injectable, signal, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ServicesGateway } from '@wilson/api/gateway';
import { AtLeast, ResolvedService, Service } from '@wilson/interfaces';
import { DateTimeFormat } from '@wilson/utils';
import { addMinutes, format } from 'date-fns';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { firstValueFrom } from 'rxjs';
import {
  MoveDirectionType,
  MoveModalComponent,
} from '../../components/move-modal/move-modal.component';
import { createFullActivityObject } from '@wilson/non-domain-specific/activities-helpers/services';

@Injectable()
export class MoveServiceHelperService {
  private _isMoveAllowedSignal = signal(false);
  public isMoveAllowedSignal = this._isMoveAllowedSignal.asReadonly();
  constructor(
    private readonly translate: TranslateService,
    private readonly nzModalService: NzModalService,
    private readonly nzMessageService: NzMessageService,
    private readonly servicesGateway: ServicesGateway,
  ) {}

  public updateServicePermission(selectedServices: ResolvedService[]): void {
    const doServicesMatchMoveConditions = !selectedServices.some((service) =>
      service.activities.some((activity) => activity.activityReports.length),
    );

    if (doServicesMatchMoveConditions) {
      this._isMoveAllowedSignal.set(true);
    } else {
      this._isMoveAllowedSignal.set(false);
    }
  }

  public openDurationSelectionModal(
    selectedServices: ResolvedService[],
    messageTemplateRef: TemplateRef<void>,
  ): Promise<Service[]> {
    let title = '';
    let description = '';
    if (selectedServices.length > 1) {
      title = this.translate.instant('service.page.move_services_header');
      description = this.translate.instant(
        'service.page.move_service_description',
      );
    } else {
      title = this.translate.instant('service.page.move_service_header');
      description = this.translate.instant(
        'service.page.move_single_service_description',
      );
    }

    const modalRef = this.nzModalService.create<
      MoveModalComponent,
      { description: string },
      Service[]
    >({
      nzTitle: title,
      nzContent: MoveModalComponent,
      nzClosable: false,
      nzCentered: true,
      nzData: {
        description,
      },
      nzFooter: [
        {
          label: this.translate.instant('general.cancel'),
          type: 'default',
          onClick: (): void => {
            modalRef.destroy();
          },
        },
        {
          label: this.translate.instant('general.apply'),
          type: 'primary',
          disabled: (modalInstance): boolean => {
            if (modalInstance?.areInputsValid) {
              return false;
            } else {
              return true;
            }
          },
          onClick: async (modalInstance): Promise<void> => {
            if (modalInstance) {
              const minutesToMove = this.getMinutesToMove(modalInstance);
              if (minutesToMove > 0) {
                await this.updateServices(
                  modalRef,
                  modalInstance,
                  selectedServices,
                  minutesToMove,
                  messageTemplateRef,
                );
              } else {
                modalRef.destroy();
              }
            }
          },
        },
      ],
    });
    return firstValueFrom(modalRef.afterClose);
  }

  private async updateServices(
    modalRef: NzModalRef<MoveModalComponent>,
    modalInstance: MoveModalComponent | undefined,
    selectedServices: ResolvedService[],
    minutesToMove: number,
    messageTemplateRef: TemplateRef<void>,
  ): Promise<void> {
    if (modalInstance) {
      const minutesToMoveReturn =
        modalInstance.moveDirection === MoveDirectionType.Forward
          ? minutesToMove
          : -minutesToMove;
      const servicesToUpdate = this.prepareServicesToUpdate(
        selectedServices,
        minutesToMoveReturn,
      );

      const updatedServices: Service[] = [];
      let isUpdateFailedForSomeService = false;
      for (const service of servicesToUpdate) {
        try {
          const updatedService = await this.servicesGateway.updateService(
            service,
          );
          updatedServices.push(updatedService);
        } catch {
          isUpdateFailedForSomeService = true;
        }
      }
      if (isUpdateFailedForSomeService) {
        this.nzMessageService.error(
          this.translate.instant('general.something_went_wrong'),
        );
      }
      if (updatedServices.length) {
        this.nzMessageService.create('', messageTemplateRef, {
          nzDuration: 5000,
          nzPauseOnHover: true,
        });
      }

      modalRef.destroy(updatedServices);
    }
  }

  private prepareServicesToUpdate(
    selectedServices: ResolvedService[],
    minutesToMove: number,
  ): AtLeast<Service, 'id'>[] {
    return selectedServices.map((service) => {
      const activities = service.activities.map((activity) => {
        return createFullActivityObject({
          activityCategoryId: activity.activityCategoryId,
          agreementId: activity.agreementId,
          createdFrom: activity.createdFrom,
          endDatetime: addMinutes(
            new Date(activity.endDatetime),
            minutesToMove,
          ).toISOString(),
          endLocationId: activity.endLocationId,
          id: activity.id,
          jobId: activity.jobId,
          name: activity.name,
          operationalStatus: activity.operationalStatus,
          professionId: activity.professionId,
          serviceId: activity.serviceId,
          shiftId: activity.shiftId,
          startDatetime: addMinutes(
            new Date(activity.startDatetime),
            minutesToMove,
          ).toISOString(),
          startLocationId: activity.startLocationId,
          viaLocationIds: [],
        });
      });
      return {
        comment: service.comment,
        id: service.id,
        name: service.name,
        organizationalUnitId: service.organizationalUnitId,
        startDate: format(
          new Date(activities[0].startDatetime),
          DateTimeFormat.DatabaseDateFormat,
        ),
        activities: activities,
      };
    });
  }

  private getMinutesToMove(modalInstance: MoveModalComponent): number {
    const selectedDays = Number(modalInstance.days);
    const selectedHours = Number(modalInstance.hours);
    const selectedMinutes = Number(modalInstance.minutes);
    const minutesToMove =
      selectedDays * 24 * 60 + selectedHours * 60 + selectedMinutes;
    return minutesToMove;
  }
}
