import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { NzButtonType } from 'ng-zorro-antd/button';
import { firstValueFrom, Observable } from 'rxjs';

export interface IconAction<T> {
  faIcon: IconDefinition;
  nzDanger?: boolean;
  // eslint-disable-next-line
  action: (entity: T) => void | Promise<any>;
  shouldDisplay?: (entity: T) => boolean;
  isLoading?: boolean;
  iconSize?: SizeProp;
}

// eslint-disable-next-line
export interface IconActionRendererParams<T = any> {
  icons: IconAction<T>[];
  isOnlyVisibleOnHover?: boolean;
  buttonType?: NzButtonType;
  hasPermission$?: Observable<boolean>;
}

@Component({
  selector: 'wilson-icon-action-renderer',
  templateUrl: './icon-action-renderer.component.html',
  styleUrls: ['./icon-action-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class IconActionRendererComponent<T>
  implements ICellRendererAngularComp
{
  @HostBinding('class.always-visible') get hostClass(): boolean {
    return !this.isOnlyVisibleOnHover;
  }

  @ViewChildren('buttonList', { read: ElementRef })
  buttonList: QueryList<ElementRef>;

  data: T;
  icons: IconAction<T>[];
  isOnlyVisibleOnHover = true;
  hasPermission = true;
  buttonType: NzButtonType = 'default';

  constructor(private changeDetectionRef: ChangeDetectorRef) {}

  async agInit(
    params: ICellRendererParams & IconActionRendererParams,
  ): Promise<void> {
    await this.populateDataAndIcons(params);
  }

  refresh(params: ICellRendererParams & IconActionRendererParams): boolean {
    this.populateDataAndIcons(params);
    return true;
  }

  async onClick(icon: IconAction<T>): Promise<void> {
    icon.isLoading = true;
    this.changeDetectionRef.detectChanges();
    await icon.action(this.data);
    icon.isLoading = false;
    this.buttonList.forEach((button) => {
      button.nativeElement.blur();
    });
    this.changeDetectionRef.detectChanges();
  }

  private async populateDataAndIcons(
    params: ICellRendererParams & IconActionRendererParams,
  ): Promise<void> {
    this.data = params.data;
    this.icons =
      params.icons?.map((icon) => ({ ...icon, isLoading: false })) ?? [];
    this.isOnlyVisibleOnHover =
      params.isOnlyVisibleOnHover ?? this.isOnlyVisibleOnHover;
    this.hasPermission =
      (await firstValueFrom(params.hasPermission$)) ?? this.hasPermission;
    this.buttonType = params.buttonType ?? 'default';
  }
}
