import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Activity, ResolvedActivity } from '@wilson/interfaces';
import { BucketType } from '@wilson/share/interfaces';
import { ShareRequestStateHelperService } from '@wilson/share/state';

@Component({
  selector: 'wilson-share-activity',
  templateUrl: './share-activity.component.html',
  styleUrls: ['./share-activity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareActivityComponent {
  @Input() activity: ResolvedActivity;
  @Input() startDateTimeOverlap: string;
  @Input() endDateTimeOverlap: string;
  @Input() isDisabled = false;
  @Output() shareStatusChanged = new EventEmitter<void>();

  get tooltipText() {
    return this.activity?.shiftId
      ? 'wilson.share.activity_already_in_shift'
      : this.activity?.jobId
      ? 'wilson.share.cannot_add_to_bucket'
      : null;
  }

  get isIconDisabled() {
    return (
      this.activity?.jobId ||
      this.activity?.shiftId ||
      this.activity.agreement ||
      this.isDisabled
    );
  }

  constructor(private shareService: ShareRequestStateHelperService) {}

  async addActivityToBucket(activity: Activity) {
    const added = await this.shareService.verifyWithUserAndAddItemsToBucket(
      this.activity.serviceId as string,
      [activity],
      BucketType.Service,
    );
    if (added) {
      this.shareStatusChanged.emit();
    }
  }

  removeActivityFromBucket(id: string) {
    this.shareService.removeItemFromBucket(
      this.activity.serviceId as string,
      id,
    );
    this.shareStatusChanged.emit();
  }
}
