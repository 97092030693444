import { BehaviorSubject, map } from 'rxjs';

const WILSON_STATE_SUBJECTS_MAP_KEY = Symbol('Wilson_State_Subjects_Map');

type WilsonStateSubject<T> = {
  subject: BehaviorSubject<T>;
  defaultValue: T;
};

type WilsonStateSubjectsMap = Map<string, WilsonStateSubject<any>>;

/**
 *
 * Automatically create a behavior subject with getter and setter for your property
 * Example:
 *
 * class MyStateService {
 *  @WilsonState<string[]>([])
 *  userIds!: string[];
 *  userIds$!: Observable<string[]>;
 * }
 *
 * Usage:
 *
 * const myService = new MyStateService();
 *
 * // Setting value
 * myService.userIds = ['123'];
 *
 * // Reading value
 * console.log(myService.userIds)
 *
 * // Subscribing to value stream
 * myService.userIds$.subscribe((ids) => {console.log(ids)})
 */
export function WilsonState<T>(defaultValue: T) {
  //eslint-disable-next-line
  return function (target: any, propertyKey: string) {
    // Initialize the Map on the target if it doesn't exist to store all properties
    if (!target[WILSON_STATE_SUBJECTS_MAP_KEY]) {
      const map: WilsonStateSubjectsMap = new Map<
        string,
        WilsonStateSubject<T>
      >();
      target[WILSON_STATE_SUBJECTS_MAP_KEY] = map;
    }

    const subject = new BehaviorSubject<T>(defaultValue);
    const valueInitializeCounterSubject = new BehaviorSubject<number>(0);
    target[WILSON_STATE_SUBJECTS_MAP_KEY].set(propertyKey, {
      subject,
      defaultValue,
    });

    Object.defineProperty(target, propertyKey, {
      get: () => subject.value,
      set: (value: T) => {
        subject.next(value);
        valueInitializeCounterSubject.next(
          valueInitializeCounterSubject.value + 1,
        );
      },
    });

    Object.defineProperty(target, `${propertyKey}$`, {
      get: () => subject.asObservable(),
    });

    Object.defineProperty(target, `${propertyKey}IsInitialized$`, {
      get: () =>
        valueInitializeCounterSubject
          .asObservable()
          .pipe(map((value) => value > 0)),
    });
  };
}

/**
 * Reset all wilson state
 * @param instance - The instance of the class that has the wilson state
 * @returns void
 * @example
 * class MyStateService {
 *  @WilsonState<string[]>([])
 *  userIds!: string[];
 *  userIds$!: Observable<string[]>;
 * }
 * public resetAllWilsonState(): void {
 *   resetAllWilsonState(this);
 * }
 */
//eslint-disable-next-line
export function resetAllWilsonState(instance: any): void {
  const subjectMap: WilsonStateSubjectsMap =
    instance[WILSON_STATE_SUBJECTS_MAP_KEY];
  if (subjectMap) {
    //eslint-disable-next-line
    subjectMap.forEach(({ subject, defaultValue }: WilsonStateSubject<any>) => {
      subject.next(defaultValue);
    });
  }
}
