import { ChangeDetectionStrategy, Component } from '@angular/core';
import { WilsonApp } from '@wilson/interfaces';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-activities-deviation-renderer',
  template: `
    <ng-container
      *ngIf="deviation !== undefined && deviation !== null; else empty"
    >
      {{ isShiftCreatedFromMobile ? '---' : (deviation | decimalToTime) }}
    </ng-container>
    <ng-template #empty> {{ 'general.n/a' | translate }} </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AzmDeviationRendererComponent implements AgRendererComponent {
  public deviation: number | undefined | null;
  public isShiftCreatedFromMobile!: boolean;

  agInit(params: ICellRendererParams): void {
    this.deviation =
      params.data?.plannedReportedDeviationDuration &&
      params.data?.plannedReportedDeviationDuration / 60;
    this.isShiftCreatedFromMobile =
      params.data?.createdFrom === WilsonApp.Mobile;
  }

  refresh(params: ICellRendererParams): boolean {
    this.agInit(params);
    return true;
  }
}
