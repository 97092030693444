@let hasShiftUpdatePermissions = (RoleAction.Update | ablePure :
RolePermissionSubject.Shift | async) ; @let hasCompanyAdminRole
=hasCompanyAdminRole$ | async;
<button
  *ngIf="
    shift?.state === ShiftState.AcceptedTimes ||
    shift?.state === ShiftState.SubmittedToPayrollProvider
  "
  nz-button
  nz-tooltip
  [nzTooltipTitle]="
    !(hasShiftUpdatePermissions || hasCompanyAdminRole)
      ? ('general.forbidden_action' | translate)
      : ''
  "
  class="w-100 fw-bold mb-2"
  (click)="updateShiftState()"
  [disabled]="disabled || !(hasShiftUpdatePermissions || hasCompanyAdminRole)"
  data-test-id="wilson-revert-shift-button:action-button"
>
  <fa-icon class="mx-2" [icon]="faUndo"></fa-icon>
  {{ 'page.shift_timeline.drawer.shift_form.revert_shift' | translate }}
</button>
