import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { FeatureFlagsModule } from '@wilson/feature-flags';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { AuthorizationModule } from '@wilson/authorization';
import { ShiftFurtherInformationComponent } from '../../shift-further-information/shift-further-information.component';
import { RoleAction, RolePermissionSubject, Shift } from '@wilson/interfaces';
import { NecessaryStayToggleComponent } from '@wilson/non-domain-specific/stays-form-and-drawer';

@Component({
  selector: 'wilson-collapsible-shift-further-information',
  standalone: true,
  imports: [
    CommonModule,
    NzCollapseModule,
    AuthorizationModule,
    FeatureFlagsModule,
    TranslateModule,
    ShiftFurtherInformationComponent,
    NecessaryStayToggleComponent,
  ],
  templateUrl: './collapsible-shift-further-information.component.html',
  styleUrl: './collapsible-shift-further-information.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapsibleShiftFurtherInformationComponent {
  @Input() shift: Shift | null = null;
  @Input() isExpandedOnOpen = false;

  @Input()
  currentShiftNecessaryStay = false;

  @Output()
  updatedNecessaryStay = new EventEmitter();

  @Output() reloadValidations = new EventEmitter();

  protected RoleAction = RoleAction;
  protected RolePermissionSubject = RolePermissionSubject;
}
