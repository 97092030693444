import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { AbilityModule } from '@casl/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FeatureFlagsModule } from '@wilson/feature-flags';
import { RoleAction, RolePermissionSubject, Shift } from '@wilson/interfaces';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { AuthorizationModule } from '@wilson/authorization';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile, NzUploadModule } from 'ng-zorro-antd/upload';
import { firstValueFrom, Observable, of, switchMap } from 'rxjs';
import { NzModalModule, NzModalService } from 'ng-zorro-antd/modal';
import { FilePreviewComponent } from '@wilson/non-domain-specific/stays-form-and-drawer';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFileUpload } from '@fortawesome/pro-light-svg-icons';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { ShiftAttachmentsDrawerStateService } from './shift-attachments-drawer/shift-attachments-drawer.service';
import { ShiftAttachmentsDrawerComponent } from './shift-attachments-drawer/shift-attachments-drawer.component';
import { downloadFile } from '@wilson/utils';
import { ShiftsService } from '@wilson/shifts';
import { format } from 'date-fns';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@Component({
  selector: 'wilson-shift-attachments',
  standalone: true,
  imports: [
    CommonModule,
    NzButtonModule,
    TranslateModule,
    FeatureFlagsModule,
    AbilityModule,
    AuthorizationModule,
    NzUploadModule,
    NzModalModule,
    FontAwesomeModule,
    NzIconModule,
    ShiftAttachmentsDrawerComponent,
    NzToolTipModule,
  ],
  templateUrl: './shift-attachments.component.html',
  styleUrl: './shift-attachments.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ShiftAttachmentsDrawerStateService],
})
export class ShiftAttachmentsComponent implements OnChanges {
  @Output() updatedAttachments = new EventEmitter<NzUploadFile[]>();
  @Output() deleteAttachments = new EventEmitter<string>();

  fileList: NzUploadFile[] = [];
  faFileUpload = faFileUpload;
  isDrawerVisible = false;
  protected RoleAction = RoleAction;
  protected RolePermissionSubject = RolePermissionSubject;
  @Input({
    required: true,
  })
  shift!: Shift;

  @Input() showAllAttachments = true;
  @Input() showAllButtonAndDrawer = true;
  @Input() showTitle = true;
  @Input() attachments = [];

  constructor(
    private readonly translate: TranslateService,
    private readonly nzMessageService: NzMessageService,
    private readonly modalService: NzModalService,
    private readonly cdr: ChangeDetectorRef,
    protected shiftAttachmentsDrawerStateService: ShiftAttachmentsDrawerStateService,
    private readonly shiftsService: ShiftsService,
  ) {}

  ngOnChanges(): void {
    this.fileList = this.attachments
      ?.map((attachment) => ({
        name: attachment.fileName,
        uid: attachment.id,
        status: 'done',
        url: attachment.presignedUrl,
      }))
      .reverse() as NzUploadFile[];
  }

  protected beforeUpload = (file: NzUploadFile): Observable<boolean> => {
    return of(file).pipe(
      switchMap((file) => {
        const allowedMaxFileSize = 50;
        const isValidFileSize =
          file?.size && file.size / 1024 / 1024 < allowedMaxFileSize;
        if (!isValidFileSize) {
          this.nzMessageService.error(
            this.translate.instant('page.stay.upload.max_size'),
          );
          return of(false);
        } else {
          return this.handleFileUpload(file);
        }
      }),
    );
  };

  protected handlePreview = (file: NzUploadFile): void => {
    const url = file.url || file.thumbUrl;
    const fileName = file.name;
    const fileExtension = fileName.split('.').pop()?.toLowerCase();

    const isWordOrExcel = ['doc', 'docx', 'xls', 'xlsx'].includes(
      fileExtension,
    );

    if (isWordOrExcel) {
      window.open(url, '_blank');
      return;
    }

    const modal = this.modalService.create<FilePreviewComponent>({
      nzContent: FilePreviewComponent,
      nzFooter: null,
      nzWidth: 650,
    });

    if (modal.componentInstance) {
      modal.componentInstance.url = url;
      modal.componentInstance.fileName = fileName;
    }
  };

  protected handleDelete = (file: NzUploadFile): void => {
    this.modalService.confirm({
      nzTitle: this.translate.instant(
        'page.shift.attachment.confirm.popup.title',
      ),
      nzContent: this.translate.instant('page.shift.attachment.confirm.popup'),
      nzOkText: this.translate.instant('general.yes'),
      nzCancelText: this.translate.instant('general.no'),
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => {
        const indexToRemove = this.fileList.findIndex(
          (data) => data.uid === file.uid,
        );
        this.fileList.splice(indexToRemove, 1);
        this.cdr.detectChanges();
        this.deleteAttachments.emit(file.uid);
        return true;
      },
    });
  };

  protected handleFileUpload = (file: NzUploadFile): Observable<boolean> => {
    return new Observable((observer) => {
      this.fileList = [file, ...this.fileList];
      this.updatedAttachments.emit(this.fileList);
      observer.next(false);
      observer.complete();
      this.cdr.detectChanges();
    });
  };

  async downloadZippedAttachments(): Promise<void> {
    try {
      const blob = await firstValueFrom(
        this.shiftsService.downloadZippedShiftAttachments(this.shift.id),
      );

      const fileName = [
        this.shift.name,
        format(new Date(this.shift.startDate), 'yyyyMMdd'),
      ].join('_');

      downloadFile(blob, fileName, 'zip');

      this.nzMessageService.success(
        this.translate.instant(
          'page.shift_details.export_shift_attachments_success_message',
        ),
      );
    } catch {
      this.nzMessageService.error(this.translate.instant('general.error'));
    }
  }

  showAll(): void {
    this.shiftAttachmentsDrawerStateService.isShiftAttachmentDrawerOpened =
      true;
  }
}
