import { TravelDirectionEnum } from '@wilson/activities/symbols';
import { Base } from '@wilson/base';
import { Agreement } from '@wilson/clients/interfaces';
import { ServiceDeviation } from '@wilson/service-deviation/interfaces';
import { WilsonApp } from '../wilson-app';
import { GeoLocation } from '../locations/geoLocation';
import { Profession } from '../profession/profession';
import { ResolvedService } from '../services/service';
import { Shift } from '../shifts/shift';
import { Job } from '../wilson-share/job';
import { ActivityCategory } from './activity-category';
import { ActivityComment } from './activity-comment';
import { ActivityReport, ResolvedActivityReport } from './activity-report';
import { JobActivityTemplate } from './activity-template';
import { OperationStatus } from './operationStatus';
import { VehicleEmissionClasses } from '../vehicles/vehicle';

export interface Activity extends Base {
  activityCategoryId: string | null;
  createdFrom: WilsonApp;
  endDatetime: string;
  endLocationId: string | null;
  name: string;
  operationalStatus: OperationStatus;
  serviceId: string | null;
  shiftId: string | null;
  jobId: string | null;
  professionId: string | null;
  startDatetime: string;
  startLocationId: string | null;
  viaLocationIds: string[];
  agreementId: string | null;
  priority: number | null;
  tonnage: number | null;
  numberOfWagons: number | null;
  organizationalUnitTransportGoodsId: number | null;
  travelDirection: TravelDirectionEnum | null;
  cargo: string | null;
  track: string | null;
  note: string | null;
  externalNumber: string | null;
  vehicleEmissionClassId: number | null;

  endLocation?: GeoLocation | null;
  activityComments?: ActivityComment[] | null;
  activityReports?: ActivityReport[] | null;
  activityCategory?: ActivityCategory | null;
  serviceDeviations?: ServiceDeviation[];
  job?: Job | null;
  profession?: Profession | null;
  agreement?: Agreement;
  viaLocations?: GeoLocation[] | null;
  startLocation?: GeoLocation | null;
  shift?: Shift;
  service?: ResolvedService | null;
}

export interface ActivityReadonlyFields {
  reportedStartDatetime: Date | null;
  reportedEndDatetime: Date | null;
  reportedStartLocation: GeoLocation;
  reportedStartLocationId: string | null;
  reportedEndLocationId: string | null;
  reportedEndLocation: GeoLocation;
  vehicleEmissionClass: VehicleEmissionClasses;
}

export interface ResolvedActivity extends Activity {
  activityCategory: ActivityCategory | null;
  startLocation: GeoLocation | null;
  endLocation: GeoLocation | null;
  activityReports: ResolvedActivityReport[];
}

export interface ResolvedActivityWithReports extends ResolvedActivity {
  activityReports: ResolvedActivityReport[];
}

export type ResolvedActivityWithRequiredActivityReportFields = Omit<
  ResolvedActivity,
  'id' | 'startLocation' | 'endLocation' | 'startLocationId' | 'endLocationId'
> & {
  id: string;
  startLocation: GeoLocation;
  endLocation: GeoLocation;
  startLocationId: string;
  endLocationId: string;
};

export function isResolvedActivityWithRequiredFields(
  activity: ResolvedActivity,
): activity is ResolvedActivityWithRequiredActivityReportFields {
  const isGeoLocation = (
    location: GeoLocation | null,
  ): location is GeoLocation => !!location && typeof location.id === 'string';

  return (
    typeof activity.id === 'string' &&
    isGeoLocation(activity.startLocation) &&
    isGeoLocation(activity.endLocation) &&
    typeof activity.startLocationId === 'string' &&
    typeof activity.endLocationId === 'string'
  );
}

export interface ActivityWithAddress extends Activity {
  startLocation: GeoLocation;
  endLocation: GeoLocation;
}

export enum ActivityFilter {
  All = 'all',
  Planned = 'planned',
  Reported = 'reported',
}

export interface SplittedActivities {
  existingActivity: ResolvedActivity;
  newActivities: (ResolvedActivity & {
    jobActivityTemplates?: JobActivityTemplate[];
  })[];
}

export enum ActivityCategoriesIdsMap {
  Disposition = '006a0f46-2c54-4aac-9234-baebaf120010',
  TrainDrive = '73b6f3fc-8d47-4cd0-8e4a-a3a3756e8ce7',
  EmptyRide = '1a6d88df-7db5-4ffc-a3d9-063518330dbe',
  GuestRide = '2c6d5343-6248-4c2a-b93a-e85ca0d6f009',
  Break = 'c70aac31-b568-4c6f-b580-e26b53770c48',
  More = 'cec090a1-e13a-47c2-bc78-76dc73be7cc5',
}

export enum ActivityAssignmentStatus {
  Assigned = 'assigned',
  Unassigned = 'unassigned',
  InShift = 'in-shift',
}
