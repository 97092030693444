import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  Stay,
  StayCardPosition,
  StaySuggestionInfo,
  LocationAndDateTimeRange,
  TimelineShift,
} from '@wilson/interfaces';
import { TimelineStayGateway } from '@wilson/api/gateway';
import { WilsonState } from '@wilson/non-domain-specific/decorators/wilson-state';
import { Observable } from 'rxjs';

@Injectable()
export class StaysDrawerStateService {
  @WilsonState<boolean>(false)
  isCreateStaysDrawerVisible!: boolean;
  isCreateStaysDrawerVisible$!: Observable<boolean>;

  @WilsonState<boolean>(false)
  isEditStaysDrawerVisible!: boolean;
  isEditStaysDrawerVisible$!: Observable<boolean>;

  @WilsonState<Stay | null>(null)
  stayToEdit!: Stay | null;
  stayToEdit$!: Observable<Stay | null>;

  @WilsonState<LocationAndDateTimeRange | null>(null)
  nextTimelineCard!: LocationAndDateTimeRange | null;
  nextTimelineCard$!: Observable<LocationAndDateTimeRange | null>;

  @WilsonState<TimelineShift | null>(null)
  previousShift!: TimelineShift | null;
  previousShift$!: Observable<TimelineShift | null>;

  @WilsonState<StaySuggestionInfo | null>(null)
  private staySuggestionInfo!: StaySuggestionInfo | null;
  staySuggestionInfo$!: Observable<StaySuggestionInfo | null>;

  constructor(
    public readonly store: Store,
    protected timelineStayGateway: TimelineStayGateway,
  ) {}

  public editStay(value: StayCardPosition) {
    this.stayToEdit = value.stay;
    this.setStayToEdit(value.stay);
    this.isEditStaysDrawerVisible = true;
  }

  public setStayToEdit(stay: Stay) {
    this.stayToEdit = stay;
  }

  public toggleCreateStaysDrawer(value: boolean) {
    this.isCreateStaysDrawerVisible = value;
  }

  public setEditStaysDrawerVisibility(value: boolean) {
    this.isEditStaysDrawerVisible = value;
  }

  public setStaysDrawerRelatedAdjacentShifts({
    nextTimelineCard,
    previousShift,
  }: {
    nextTimelineCard: LocationAndDateTimeRange;
    previousShift: TimelineShift | null;
  }) {
    this.nextTimelineCard = nextTimelineCard;
    this.previousShift = previousShift;
  }

  public setStaySuggestionInfo(staySuggestionInfo: StaySuggestionInfo) {
    this.staySuggestionInfo = staySuggestionInfo;
    this.previousShift = staySuggestionInfo.shift;
  }

  public resetSetShifts() {
    this.nextTimelineCard = null;
    this.previousShift = null;
    this.staySuggestionInfo = null;
  }

  resetEditStayData() {
    this.stayToEdit = null;
    this.isEditStaysDrawerVisible = false;
  }
}
