import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  RoleAction,
  RoleName,
  RolePermissionSubject,
  Shift,
  ShiftState,
} from '@wilson/interfaces';
import { AbilityModule } from '@casl/angular';
import { firstValueFrom } from 'rxjs';
import { ShiftsService } from '@wilson/shifts';
import { NzMessageService } from 'ng-zorro-antd/message';
import { faUndo } from '@fortawesome/pro-solid-svg-icons';
import { AuthorizationModule, RolesPipe } from '@wilson/authorization';
import { NzModalService } from 'ng-zorro-antd/modal';
import { HttpErrorResponse } from '@angular/common/http';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@Component({
  selector: 'wilson-revert-shift-button',
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    TranslateModule,
    NzButtonModule,
    AbilityModule,
    AuthorizationModule,
    NzToolTipModule,
  ],
  templateUrl: './revert-shift-button.component.html',
  styleUrl: './revert-shift-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RevertShiftButtonComponent {
  @Input({
    required: true,
  })
  shift!: Shift;
  @Input() disabled = false;
  @Output() updateSuccess = new EventEmitter<void>();

  public faUndo = faUndo;
  public ShiftState = ShiftState;

  protected RoleAction = RoleAction;
  protected RolePermissionSubject = RolePermissionSubject;
  hasCompanyAdminRole$ = this.rolesPipe.transform([RoleName.CompanyAdmin]);
  constructor(
    private readonly shiftsService: ShiftsService,
    private readonly translate: TranslateService,
    private readonly message: NzMessageService,
    private readonly rolesPipe: RolesPipe,
    private nzModalService: NzModalService,
    private readonly translateService: TranslateService,
  ) {}

  async updateShiftState() {
    const modalRef = this.nzModalService.confirm({
      nzTitle: this.translateService.instant(
        'page.revert-shift.modal-warning.title',
      ),
      nzContent: this.translateService.instant(
        'page.revert-shift.modal-warning.content',
      ),
      nzOnOk: async () => {
        try {
          await firstValueFrom(
            this.shiftsService.updateShiftsBulk([
              { id: this.shift.id, state: ShiftState.Submitted },
            ]),
          );
          this.updateSuccess.emit();
          this.message.success(this.translate.instant('popup.save.text'));
          modalRef.destroy();
        } catch (error) {
          if (error instanceof HttpErrorResponse) {
            this.message.error(
              this.translate.instant('general.something_went_wrong'),
            );
          }
        }
      },
    });
  }
}
