import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthorizationModule } from '@wilson/authorization';
import { FeatureFlagsModule } from '@wilson/feature-flags';
import { Shift, RoleAction, RolePermissionSubject } from '@wilson/interfaces';
import { ShiftsService } from '@wilson/shifts';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'wilson-shift-further-information',
  standalone: true,
  imports: [
    NzSwitchModule,
    FormsModule,
    FeatureFlagsModule,
    CommonModule,
    TranslateModule,
    NzSelectModule,
    AuthorizationModule,
    NzToolTipModule,
  ],
  templateUrl: './shift-further-information.component.html',
  styleUrl: './shift-further-information.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShiftFurtherInformationComponent {
  private shiftId: string;
  public excludeFromValidationCalculation = false;

  @Output() reloadValidations = new EventEmitter();

  @Input() set shift(value: Shift | undefined) {
    if (value) {
      this.shiftId = value.id;
      this.excludeFromValidationCalculation =
        value.excludeInValidation || false;
    }
  }
  RoleAction = RoleAction;
  RolePermissionSubject = RolePermissionSubject;

  constructor(
    private cdr: ChangeDetectorRef,
    private shiftGateway: ShiftsService,
    private nzMessageService: NzMessageService,
    private translate: TranslateService,
  ) {}

  public async updateCalculationExclusion(exclude: boolean): Promise<void> {
    this.excludeFromValidationCalculation = exclude;
    try {
      await firstValueFrom(
        this.shiftGateway.updateShift({
          id: this.shiftId,
          excludeInValidation: exclude,
        } as Shift),
      );
      this.reloadValidations.emit();
    } catch {
      this.nzMessageService.error(this.translate.instant('general.error'));
      this.cdr.detectChanges();
    }
  }
}
