import { NzPresetColor } from 'ng-zorro-antd/core/color';
import { FormControl } from '@angular/forms';
import { StrictBase } from '@wilson/base';
import { User } from '../accounts/User';
import { GeoLocation } from '../locations/geoLocation';
import { TimelineShift } from '../timeline/shift-timeline-custom-types';

export enum TimelineStayManagementDrawerFormFieldEnum {
  IsOverNightStayRequired = 'isOverNightStayRequired',
  LocationId = 'locationId',
  StayStatus = 'stayStatus',
  StayType = 'stayType',
  TimeFrame = 'timeFrame',
  CheckInDateTime = 'checkInDateTime',
  CheckOutDateTime = 'checkOutDateTime',
  CancellationDate = 'cancellationDate',
  PriceTotal = 'priceTotal',
  PaidNights = 'paidNights',
  AccomodationName = 'accommodationName',
  Address = 'address',
  PhoneNumber = 'phoneNumber',
  Note = 'note',
  UserId = 'userId',
  Attachments = 'attachments',
  CostCenter = 'costCenter',
  Creditor = 'creditor',
  Email = 'email',
  InvoiceReference = 'invoiceReference',
  StandardRate = 'standardRate',
}

export interface TimelineStayManagementDrawerFormValue {
  [TimelineStayManagementDrawerFormFieldEnum.IsOverNightStayRequired]:
    | boolean
    | null;
  [TimelineStayManagementDrawerFormFieldEnum.LocationId]: string | null;
  [TimelineStayManagementDrawerFormFieldEnum.StayStatus]: StayStatus | null;
  [TimelineStayManagementDrawerFormFieldEnum.StayType]: StayType | null;
  [TimelineStayManagementDrawerFormFieldEnum.TimeFrame]: DateRange | null;
  [TimelineStayManagementDrawerFormFieldEnum.CheckInDateTime]: Date | null;
  [TimelineStayManagementDrawerFormFieldEnum.CheckOutDateTime]: Date | null;
  [TimelineStayManagementDrawerFormFieldEnum.CancellationDate]: Date | null;
  [TimelineStayManagementDrawerFormFieldEnum.PriceTotal]: number | null;
  [TimelineStayManagementDrawerFormFieldEnum.PaidNights]: number | null;
  [TimelineStayManagementDrawerFormFieldEnum.AccomodationName]: string | null;
  [TimelineStayManagementDrawerFormFieldEnum.Address]: string | null;
  [TimelineStayManagementDrawerFormFieldEnum.PhoneNumber]: string | null;
  [TimelineStayManagementDrawerFormFieldEnum.Note]: string | null;
  [TimelineStayManagementDrawerFormFieldEnum.UserId]: string | null;
  [TimelineStayManagementDrawerFormFieldEnum.Attachments]:
    | StayAttachment[]
    | null;
  [TimelineStayManagementDrawerFormFieldEnum.CostCenter]: string | null;
  [TimelineStayManagementDrawerFormFieldEnum.Creditor]: string | null;
  [TimelineStayManagementDrawerFormFieldEnum.Email]: string | null;
  [TimelineStayManagementDrawerFormFieldEnum.InvoiceReference]: string | null;
  [TimelineStayManagementDrawerFormFieldEnum.StandardRate]: number | null;
}

export type TimelineStaysDrawerFormControls = {
  [Property in keyof TimelineStayManagementDrawerFormValue]: FormControl<
    TimelineStayManagementDrawerFormValue[Property]
  >;
};

export interface StayStatusValue {
  id: number;
  name: string;
  value: StayStatus;
  colorCode: NzPresetColor | undefined;
}

export enum StayStatus {
  Open = 'open',
  Requested = 'requested',
  Confirmed = 'confirmed',
  Invoiced = 'invoiced',
  Cancelled = 'cancelled',
  ShortTermCancelled = 'shortTermCancelled',
}

export type DateRange = [fromDate: Date, toDate: Date];

export enum StayType {
  Hotel = 'hotel',
  CompanyRoom = 'companyRoom',
  Private = 'private',
}

export interface StayDTO {
  userId: string;
  locationId: string;
  type: StayType;
  startDatetime: string;
  endDatetime: string;
  accommodationName: string | undefined;
  address: string | undefined;
  note: string | undefined;
  numPaidNights: number | undefined;
  phoneNumber: string | undefined;
  cancellationDate: string | null;
  priceTotal: number | undefined;
  status: StayStatus;
  costCenter: string | undefined;
  creditor: string | undefined;
  email: string | undefined;
  invoiceReference: string | undefined;
  standardRate: number | undefined;
}
export type Stay = StayDTO & Attachment & StrictBase;

export type StayListItem = Stay & {
  user: User | null;
  location: GeoLocation | null | undefined;
};

export interface StaySearchOptions {
  startDatetime: string;
  endDatetime: string;
  userIds: string[];
  statuses: StayStatus[];
  locationId: string;
  types: StayType[];
}

export const StayStatusColor: Record<StayStatus, NzPresetColor> = {
  [StayStatus.Open]: 'red',
  [StayStatus.Requested]: 'volcano',
  [StayStatus.Invoiced]: 'blue',
  [StayStatus.Cancelled]: 'geekblue',
  [StayStatus.Confirmed]: 'gold',
  [StayStatus.ShortTermCancelled]: 'pink',
};

export interface StayCardPosition {
  left: number | null;
  right: number | null;
  stay: Stay;
}

export interface StayInfo {
  locationId: string;
  userId: string;
  shiftId: string;
  locationName: string;
  userName: string;
  date: string;
  cancellationDate: string | null;
}

export interface StaySuggestionInfo {
  locationId: string;
  userId: string;
  shiftId: string;
  shift: TimelineShift;
  locationName: string;
  userName: string;
  date: string;
  cancellationDate: string | null;
}

export interface Attachment {
  attachments: StayAttachment[];
}

export interface StayAttachment extends StrictBase {
  filename: string;
  presignedUrl: string;
  isInvoice: boolean;
  stayId: string;
}
