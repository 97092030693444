@let hasShiftUpdatePermission = !!(RoleAction.Update | ablePure :
RolePermissionSubject.Shift | async);
<div
  class="row"
  *ngIf="
    'portal-exclude-shift-from-validation-calculation' | featureFlagPure | async
  "
>
  <div class="col-9">
    {{ 'page.shifts.exclude_from_validation_calculation' | translate }}
  </div>
  @let hasShiftUpdatePermissions = RoleAction.Update | ablePure :
  RolePermissionSubject.Shift | async;
  <div class="col-3 toggle-container">
    <nz-switch
      nz-tooltip
      [nzTooltipTitle]="
        !hasShiftUpdatePermissions
          ? ('general.forbidden_action' | translate)
          : ''
      "
      [nzDisabled]="!hasShiftUpdatePermissions"
      [(ngModel)]="excludeFromValidationCalculation"
      [nzCheckedChildren]="checkedTemplate"
      (ngModelChange)="updateCalculationExclusion($event)"
      class="d-flex justify-content-end"
      [nzDisabled]="!hasShiftUpdatePermission"
      nz-tooltip
      [nzTooltipTitle]="
        !hasShiftUpdatePermission
          ? ('general.forbidden_action' | translate)
          : ''
      "
      data-test-id="wilson-shift-further-information:exclude-from-validation-calculation"
    ></nz-switch>
  </div>
</div>

<ng-template #checkedTemplate>
  <i class="fa fa-check icon-mar-top"></i>
</ng-template>
