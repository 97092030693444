import { authGuardFn } from '@wilson/auth/core';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { ImpressumComponent } from './impressum/impressum.component';

const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    children: [
      {
        path: '',
        data: {
          breadcrumb: 'tooltip.home',
        },
        canActivate: [authGuardFn],
        loadChildren: () =>
          import('./portal/portal.module').then((m) => m.PortalModule),
      },
    ],
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'datenschutz',
    component: DatenschutzComponent,
  },
  {
    path: 'impressum',
    component: ImpressumComponent,
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
