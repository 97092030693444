@let hasShiftUpdatePermissions = (RoleAction.Update | ablePure :
RolePermissionSubject.Shift | async) ;
<section
  class="d-flex align-items-center justify-content-between mx-1 pb-2 mt-2"
>
  <div class="fw-bold text-dark notes-heading-text">
    {{ 'general.notes' | translate }}
    ({{ shiftNotes().length }})
  </div>
  <button
    nz-button
    nzType="text"
    nz-tooltip
    [nzTooltipTitle]="
      !hasShiftUpdatePermissions ? ('general.forbidden_action' | translate) : ''
    "
    [disabled]="!hasShiftUpdatePermissions"
    (click)="openNotesPopup()"
  >
    <i class="fas fa-plus icon-style ml-2"></i>
  </button>
</section>

<section *ngFor="let shiftNote of shiftNotes()" class="note_container">
  <div>
    <div class="note_users-creation">
      {{ shiftNote.createdByUserId | userName | async }} •
      {{ shiftNote.createdAt | date : DateTimeFormat.GermanDateTimeFormat }}
    </div>
    <div class="note_details">
      {{ shiftNote.note }}
    </div>
  </div>

  <fa-icon
    nz-tooltip
    [nzTooltipTitle]="
      !hasShiftUpdatePermissions ? ('general.forbidden_action' | translate) : ''
    "
    (click)="hasShiftUpdatePermissions && deleteNote(shiftNote.id)"
    class="red-icon ml-2 note_delete-icon"
    [icon]="faTrash"
  ></fa-icon>
</section>
