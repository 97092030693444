export class FetchKpisForAllFilteredUsers {
  static readonly type = '[TimelineKpis] fetch users Kpis';
  constructor(public userIds: string[], public showLoading: boolean) {}
}

export class UpdateKpisOfUsers {
  static readonly type = '[TimelineKpis] update users Kpis';
  constructor(public userIds: string[]) {}
}

export class SubscribeToKpiRequests {
  static readonly type = '[TimelineKpis] subscribe to kpi requests';
}

export class UnsubscribeFromKpiRequests {
  static readonly type = '[TimelineKpis] unsubscribe from kpi requests';
}
