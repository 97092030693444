import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BackendService } from '@wilson/base';
import { ConfigOptions, ConfigService } from '@wilson/config';
import {
  ActivityCategoryPayrollSetting,
  CreateActivityCategoryPayrollSetting,
} from '@wilson/interfaces';
import { TransformMasterDataTranslationService } from '@wilson/non-domain-specific/master-data-translate';
import { firstValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ActivityCategoryPayrollSettingsGateway extends BackendService<ActivityCategoryPayrollSetting> {
  protected readonly path = 'activity-category-payroll-settings';

  constructor(
    protected readonly http: HttpClient,
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
    protected readonly transformMasterDataTranslationService: TransformMasterDataTranslationService,
  ) {
    super();
  }

  public getActivityCategoryPayrollSettingByPayrollSettingId(
    id: string,
  ): Observable<ActivityCategoryPayrollSetting[]> {
    return this.http.get<ActivityCategoryPayrollSetting[]>(
      `${this.config.host}/${this.path}/${id}`,
    );
  }

  public createOrUpdateActivityCategoryPayrollSetting(
    payload: CreateActivityCategoryPayrollSetting,
  ): Observable<ActivityCategoryPayrollSetting[]> {
    return this.http.put<ActivityCategoryPayrollSetting[]>(
      `${this.config.host}/${this.path}`,
      payload,
    );
  }

  public deleteActivityCategoryPayrollSetting(
    payrollSettingId: string,
    activityCategoryId: string,
  ): Promise<ActivityCategoryPayrollSetting[]> {
    return firstValueFrom(
      this.http.delete<ActivityCategoryPayrollSetting[]>(
        `${this.config.host}/${this.path}/${payrollSettingId}/${activityCategoryId}`,
      ),
    );
  }
}
