@let hasQualificationReadPermissions = RoleAction.Read | ablePure:
RolePermissionSubject.Qualification | async;
@if(hasQualificationReadPermissions){
<div
  (click)="!disabled && openQualificationSelection()"
  [class.cursor-pointer]="!disabled"
  nz-popover
  [nzPopoverTitle]="'page.settings.qualifications' | translate"
  [nzPopoverContent]="qualificationPopoverContent"
>
  <img
    [src]="
      counter === 0
        ? 'assets/img/qualification/Icon-Qualifications.svg'
        : 'assets/img/qualification/Active-Icon-Qualifications.svg'
    "
  />
</div>

<ng-template #qualificationPopoverContent>
  <ul *ngIf="tooltipList?.length; else noQualifications">
    <li *ngFor="let item of tooltipList">
      {{ item | translate }}
    </li>
  </ul>

  <ng-template #noQualifications>
    {{ 'activity_qualification_button.popover.no_qualifications' | translate }}
  </ng-template>
</ng-template>
}
