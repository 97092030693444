import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import {
  CorePayrollCategory,
  FindConditions,
  IntervalDate,
  OvertimeCompensationTransaction,
  PayrollProvider,
  PayrollRecalculationRequest,
  PayrollTransaction,
  PayrollTransactionsSum,
  TimeFrame,
  UserWithPayrollTimeSheet,
} from '@wilson/interfaces';
import { TransformMasterDataTranslationService } from '@wilson/non-domain-specific/master-data-translate';
import { createRelationsQueryParamString } from '@wilson/utils';
import { map, Observable, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PayrollService {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(ConfigService)
    private readonly config: ConfigOptions,
    private readonly transformMasterDataTranslationService: TransformMasterDataTranslationService,
  ) {}

  getPayrollTransactionReport(
    startDate: string,
    endDate: string,
    userIds: string[],
    overtimeBonusTimeFrame: TimeFrame | undefined,
    payrollProviderId: string,
    accumulateTransactions: boolean,
    exportWithCostCenter: boolean,
    wageDate?: string,
  ) {
    return this.httpClient.post(
      `${this.config.host}/payroll-transactions/report`,
      {
        startDate,
        endDate,
        userIds,
        overtimeBonusTimeFrame,
        payrollProviderId,
        accumulateTransactions,
        exportWithCostCenter,
        wageDate,
      },
      { responseType: 'text' },
    );
  }

  getPayrollTransactionSumForUser(
    userId: string,
    timeRanges: IntervalDate[],
  ): Observable<PayrollTransactionsSum[]> {
    return this.httpClient.post<PayrollTransactionsSum[]>(
      `${this.config.host}/payroll-transactions/users/${userId}/sum`,
      { timeRanges },
    );
  }

  public getPayrollCategories() {
    return this.httpClient
      .get<CorePayrollCategory[]>(`${this.config.host}/payroll-categories`)
      .pipe(
        shareReplay(1),
        map((payrollCategories) => {
          return this.transformMasterDataTranslationService.transform(
            payrollCategories,
          );
        }),
      );
  }

  public getPayrollTransactions(
    conditions: FindConditions<PayrollTransaction>,
    relations?: string[],
  ) {
    const relationsQueryParams = relations
      ? createRelationsQueryParamString(relations)
      : '';
    return this.httpClient.post<PayrollTransaction[]>(
      `${this.config.host}/payroll-transactions/search?${relationsQueryParams}`,
      conditions,
    );
  }

  public getPayrollTransactionsSum(
    userId: string,
    payrollCategoryId: string,
    startDate: string,
    endDate: string,
  ) {
    return this.httpClient.post<number>(
      `${this.config.host}/payroll-transactions/calculate-sum`,
      {
        startDate,
        endDate,
        userId,
        payrollCategoryId,
      },
    );
  }

  public createPayrollTransactions(
    payrollTransactions: Omit<PayrollTransaction, 'id'>[],
  ) {
    return this.httpClient.post<PayrollTransaction[]>(
      `${this.config.host}/payroll-transactions`,
      { items: payrollTransactions },
    );
  }

  public updatePayrollTransactions(
    id: string,
    payrollTransaction: Partial<PayrollTransaction>,
  ) {
    return this.httpClient.patch<PayrollTransaction>(
      `${this.config.host}/payroll-transactions/${id}`,
      payrollTransaction,
    );
  }

  public deletePayrollTransactions(id: string) {
    return this.httpClient.delete<PayrollTransaction>(
      `${this.config.host}/payroll-transactions/${id}`,
    );
  }

  public getPayrollCategory(conditions: FindConditions<CorePayrollCategory>) {
    return this.httpClient.post<CorePayrollCategory[]>(
      `${this.config.host}/payroll-categories/search`,
      conditions,
    );
  }

  createOvertimePayrollTransaction(
    transaction: OvertimeCompensationTransaction,
  ) {
    return this.httpClient.post(
      `${this.config.host}/overtime-compensations`,
      transaction,
    );
  }

  public getUsersTimeSheet(
    conditions: FindConditions<UserWithPayrollTimeSheet>,
  ) {
    return this.httpClient.post(
      `${this.config.host}/payroll-export/payroll-time-sheet`,
      conditions,
    );
  }

  public getPayrollProviders(): Observable<PayrollProvider[]> {
    return this.httpClient.get<PayrollProvider[]>(
      `${this.config.host}/payroll-providers`,
    );
  }

  public recalculatePayroll(payload: PayrollRecalculationRequest) {
    return this.httpClient.put<PayrollRecalculationRequest>(
      `${this.config.host}/payroll-transaction-recalculations`,
      payload,
    );
  }
}
