import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { ShiftTimelineDataState } from './shift-timeline-data/shift-timeline-data.state';
import { ShiftTimelineState } from './shift-timeline.state';
import { ShiftTimelineFiltersState } from './shift-timeline-filters/shift-timeline-filters.state';
import { ShiftTimelineInteractionsState } from './shift-timeline-interactions/shift-timeline-interactions.state';
import { ShiftTimelineUnassignedRegionState } from './shift-timeline-unassigned-region/shift-timeline-unassigned-region.state';
import { ShiftTimelineDrawerState } from './shift-timeline-drawer/shift-timeline-drawer.state';
import { TimelineModalFilterState } from './timeline-modal-filters/timeline-modal-filters.state';
import { TimelineDataUtilityState } from './timeline-data-utility/timeline-data-utility.state';
import { ShiftTimelineFilterUnassignedRegionState } from './shift-timeline-filter-unassigned-region/shift-timeline-filter-unassigned-region.state';
import { TimelineKpisState } from './timeline-kpis/timeline-kpis.state';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([
      ShiftTimelineState,
      ShiftTimelineDataState,
      ShiftTimelineFiltersState,
      ShiftTimelineInteractionsState,
      ShiftTimelineUnassignedRegionState,
      ShiftTimelineFilterUnassignedRegionState,
      ShiftTimelineDrawerState,
      TimelineModalFilterState,
      TimelineDataUtilityState,
      TimelineKpisState,
    ]),
  ],
})
export class ShiftTimelineStateModule {}
