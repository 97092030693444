import {
  GeoLocation,
  OperationStatus,
  OrganizationalUnit,
  PublicationStatus,
  ShiftCategoriesIdsMap,
  ShiftState,
  TimelineShiftWithActivities,
} from '@wilson/interfaces';
import { CreateNewViaDragAndDropService } from '../create-new-via-drag-and-drop.service';
import { WilsonApp } from '@wilson/interfaces';
import { createFullActivityObject } from '@wilson/non-domain-specific/activities-helpers/services';

export function generateDummyShift({
  orgUnit,
  location,
  shiftStartDate,
  shiftEndDate,
  userId,
  activityCategoryId,
  shiftName,
}: {
  orgUnit: OrganizationalUnit;
  location: GeoLocation;
  shiftStartDate: string;
  shiftEndDate: string;
  userId: string;
  activityCategoryId: string;
  shiftName: string;
}): TimelineShiftWithActivities {
  const shiftCategoryId = ShiftCategoriesIdsMap.Normal;

  return {
    id: CreateNewViaDragAndDropService.MOCK_SHIFT_ID,
    comment: '',
    name: shiftName,
    organizationalUnitId: orgUnit?.id as string,
    publicationStatus: PublicationStatus.NotPublished,
    shiftCategoryId: shiftCategoryId,
    startDate: shiftStartDate,
    userId,
    state: ShiftState.NotSubmitted,
    priority: null,
    activities: [
      createFullActivityObject({
        activityCategoryId: activityCategoryId,
        createdFrom: WilsonApp.Portal,
        endDatetime: shiftEndDate,
        endLocationId: orgUnit.locationId as string,
        endLocation: location,
        jobId: null,
        name: '',
        operationalStatus: OperationStatus.NotStarted,
        professionId: null,
        serviceId: null,
        shiftId: null,
        startDatetime: shiftStartDate,
        startLocationId: orgUnit.locationId as string,
        startLocation: location,
        viaLocationIds: [],
      }),
    ],

    ignoreStayNecessary: false,
    startDatetime: shiftStartDate,
    endDatetime: shiftEndDate,
    deviatedStartDatetime: null,
    deviatedEndDatetime: null,
    reportedStartDatetime: null,
    reportedEndDatetime: null,
    startLocationId: orgUnit.locationId as string,
    endLocationId: orgUnit.locationId as string,
    startLocation: location as GeoLocation & { locationCode: string },
    endLocation: location as GeoLocation & { locationCode: string },
    reportedStartLocationId: null,
    reportedEndLocationId: null,
    reportedStartLocation: null,
    reportedEndLocation: null,
    operationalStatus: OperationStatus.NotStarted,
    projectId: null,
    isHighlighted: true,
  };
}
