@let hasShiftAttachmentCreatePermission = !!(RoleAction.Create | ablePure :
RolePermissionSubject.ShiftAttachment | async); @let
hasShiftAttachmentDeletePermission = !!(RoleAction.Delete | ablePure :
RolePermissionSubject.ShiftAttachment | async);

<div class="attachment-wrapper">
  @if(showTitle){
  <p class="attachment-title">{{ 'general.attachments' | translate }}</p>
  }
  <nz-upload
    [class.custom-upload]="showAllAttachments"
    class="nz-upload-list-after"
    [nzFileList]="fileList"
    [nzAccept]="
      'image/jpeg,image/png,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    "
    [nzBeforeUpload]="beforeUpload"
    [nzPreview]="handlePreview"
    [nzRemove]="hasShiftAttachmentDeletePermission ? handleDelete : false"
    [nzDisabled]="!hasShiftAttachmentCreatePermission"
  >
    <div class="button-and-text">
      <button
        nz-button
        class="button-upload"
        nz-tooltip
        [nzTooltipTitle]="
          !hasShiftAttachmentCreatePermission
            ? ('general.forbidden_action' | translate)
            : null
        "
        [disabled]="!hasShiftAttachmentCreatePermission"
        data-test-id="wilson-shift-attachments:upload-button"
      >
        <span nz-icon nzType="upload" class="upload-icon"></span>
        {{ 'general.upload' | translate }}
      </button>
      <p class="upload-hint">
        {{ 'general.upload.description_type' | translate }}
      </p>
    </div>
  </nz-upload>
</div>
@if(fileList?.length > 0){
<div class="action-buttons">
  @let hasShiftReadPermission = !!(RoleAction.Read | ablePure :
  RolePermissionSubject.Shift | async);
  <button
    nz-button
    nzType="text"
    nz-tooltip
    [nzTooltipTitle]="
      !hasShiftReadPermission ? ('general.forbidden_action' | translate) : ''
    "
    (click)="downloadZippedAttachments()"
    [disabled]="!hasShiftReadPermission || fileList?.length === 0"
    data-test-id="wilson-shift-attachments:download-button"
  >
    {{ 'general.zip_download' | translate }}
  </button>
  @if(showAllButtonAndDrawer){
  <button
    nz-button
    nz-tooltip
    [nzTooltipTitle]="
      !hasShiftReadPermission ? ('general.forbidden_action' | translate) : ''
    "
    (click)="showAll()"
    class="show-all-attachments"
    [disabled]="!hasShiftReadPermission || fileList?.length === 0"
    data-test-id="wilson-shift-attachments:showAll-button"
  >
    {{ 'page.shift.show_all_attachments' | translate }}
  </button>
  }
</div>
} @if ((shiftAttachmentsDrawerStateService.isShiftAttachmentDrawerOpened$ |
async) && showAllButtonAndDrawer){
<wilson-shift-attachments-drawer
  [fileList]="fileList"
  (showFilePreview)="handlePreview($event)"
  (deleteFile)="handleDelete($event)"
></wilson-shift-attachments-drawer>
}
