import { Pipe, PipeTransform } from '@angular/core';
import { Activity } from '@wilson/interfaces';
import { isShiftInPast } from './is-shift-in-past.fn';

@Pipe({
  name: 'isShiftInPastPipe',
})
export class IsShiftInPastPipe implements PipeTransform {
  transform(activities: Activity[]): boolean {
    return isShiftInPast(activities);
  }
}
