import { Injectable } from '@angular/core';
import { Action, State, StateContext, createSelector } from '@ngxs/store';
import {
  SetSideNavCollapsedStateSetting,
  SetSideNavExpandedTile,
  SetSideNavSetting,
} from './side-nav-settings.actions';
import { patch } from '@ngxs/store/operators';
import { cloneDeep } from 'lodash';

export enum TileType {
  ServicesTile = 'servicesTile',
  ShiftsTile = 'shiftsTile',
  CalendarTile = 'calendarTile',
  ShareTile = 'shareTile',
  StaffTile = 'staffTile',
  PayrollTile = 'payrollTile',
  InvoicingTile = 'invoicingTile',
  QualificationTile = 'qualificationTile',
}

export interface SideNavSetting {
  isCollapsed: boolean;
  sideNavExpandedTile?: TileType;
}

export interface SideNavSettingModel {
  sideNavSettings: {
    [userId in string]: SideNavSetting;
  };
}

const defaults: SideNavSettingModel = {
  sideNavSettings: {},
};

@State<SideNavSettingModel>({
  name: 'sideNavSettings',
  defaults,
})
@Injectable()
export class SideNavSettingsState {
  static getSideNavSetting(userId: string) {
    return createSelector(
      [SideNavSettingsState],
      (state: SideNavSettingModel) => {
        return state.sideNavSettings[userId];
      },
    );
  }

  static getIsSideNavCollapsed(userId: string) {
    return createSelector(
      [SideNavSettingsState],
      (state: SideNavSettingModel) => {
        return state.sideNavSettings[userId].isCollapsed;
      },
    );
  }

  static getExpandedTile(userId: string) {
    return createSelector(
      [SideNavSettingsState],
      (state: SideNavSettingModel) => {
        return state.sideNavSettings[userId].sideNavExpandedTile;
      },
    );
  }

  @Action(SetSideNavSetting)
  setSideNavSetting(
    ctx: StateContext<SideNavSettingModel>,
    { userId, sideNavSettings }: SetSideNavSetting,
  ) {
    const state = ctx.getState();
    const sideNavSettingsToUpdate = cloneDeep(state.sideNavSettings);
    sideNavSettingsToUpdate[userId] = sideNavSettings;
    ctx.patchState({
      sideNavSettings: sideNavSettingsToUpdate,
    });
  }

  @Action(SetSideNavExpandedTile)
  setExpandedTileSetting(
    ctx: StateContext<SideNavSettingModel>,
    { userId, sideNavExpandedTile }: SetSideNavExpandedTile,
  ) {
    ctx.setState(
      patch({
        sideNavSettings: patch({
          [userId]: patch({
            sideNavExpandedTile: sideNavExpandedTile,
          }),
        }),
      }),
    );
  }

  @Action(SetSideNavCollapsedStateSetting)
  setIsCollapsedSetting(
    ctx: StateContext<SideNavSettingModel>,
    { userId, isCollapsed }: SetSideNavCollapsedStateSetting,
  ) {
    ctx.setState(
      patch({
        sideNavSettings: patch({
          [userId]: patch({
            isCollapsed: isCollapsed,
          }),
        }),
      }),
    );
  }
}
