import { Injectable } from '@angular/core';
import { Agreement } from '@wilson/clients/interfaces';
import { WilsonState } from '@wilson/non-domain-specific/decorators/wilson-state';
import { BehaviorSubject, map, Observable, of, catchError } from 'rxjs';
import { AgreementsService } from './agreements.service';

@Injectable({
  providedIn: 'root',
})
export class AgreementsCacheService {
  @WilsonState<Record<string, Agreement>>({})
  private agreements!: Record<string, Agreement>;

  private isRequestInFlight = false;
  private agreementsStream$ = new BehaviorSubject<Record<string, Agreement>>(
    this.agreements,
  );

  constructor(private agreementsService: AgreementsService) {}

  getAgreementsFromCache(ids: string[]): Observable<Agreement[]> {
    const cachedAgreements = ids
      .map((id) => this.agreements[id])
      .filter((agreement): agreement is Agreement => !!agreement);
    const missingIds = ids.filter((id) => !this.agreements[id]);

    if (missingIds.length === 0) {
      return of(cachedAgreements);
    }

    if (!this.isRequestInFlight) {
      this.isRequestInFlight = true;

      this.agreementsService
        .getMany({ relations: ['client'], limit: 9999 })
        .pipe(
          map((response) => {
            this.agreements = response.data.reduce(
              (acc, agreement) => ({
                ...acc,
                [agreement.id as string]: agreement,
              }),
              {},
            );
            this.isRequestInFlight = false;
            this.agreementsStream$.next(this.agreements);
          }),
          catchError((error) => {
            this.isRequestInFlight = false;
            console.error('Failed to fetch agreements:', error);
            return of({});
          }),
        )
        .subscribe();
    }

    return this.agreementsStream$.pipe(
      map((agreements) => {
        return ids
          .map((id) => agreements[id])
          .filter((agreement): agreement is Agreement => !!agreement);
      }),
    );
  }

  clearAgreementsCache(): void {
    this.agreements = {};
    this.agreementsStream$.next(this.agreements);
    this.isRequestInFlight = false;
  }
}
