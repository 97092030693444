<div class="d-flex">
  <div class="flex-fill me-2">
    <input
      type="text"
      [placeholder]="'comments.placeholder' | translate"
      [(ngModel)]="text"
      (keyup.enter)="send()"
      (keydown.enter)="$event.preventDefault()"
      nz-input
    />
  </div>

  <button
    (click)="send()"
    [disabled]="!hasUpdatePermissions || text.length === 0"
    nz-button
    nzShape="circle"
    nz-tooltip
    [nzTooltipTitle]="
      !hasUpdatePermissions ? ('general.forbidden_action' | translate) : ''
    "
  >
    <i class="fas fa-arrow-right"></i>
  </button>
</div>
