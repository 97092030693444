@let hasShiftUpdatePermission = !!(RoleAction.Update | ablePure :
RolePermissionSubject.Shift | async); @let hasStayUpdatePermission =
!!(RoleAction.Update | ablePure : RolePermissionSubject.Stay | async);

<div class="row mb-2">
  <div [ngClass]="{ 'fw-bold': boldText }" class="col-9 form-title text-dark">
    {{
      'page.shift_timeline.hotel_stay.drawer.form.isOverNightStayRequired.toggle'
        | translate
    }}
  </div>
  <div class="col-3 toggle-container">
    <nz-switch
      nz-tooltip
      [nzTooltipTitle]="
        !hasNecessaryStayUpdatePermissions
          ? ('general.forbidden_action' | translate)
          : ''
      "
      [nzDisabled]="!hasNecessaryStayUpdatePermissions"
      class="d-flex justify-content-end"
      [ngModel]="currentShiftNecessaryStay"
      (ngModelChange)="updatedNecessaryStay.emit($event)"
      [nzCheckedChildren]="checkedTemplate"
      [nzDisabled]="!hasStayUpdatePermission || !hasShiftUpdatePermission"
      nz-tooltip
      [nzTooltipTitle]="
        !hasStayUpdatePermission || !hasShiftUpdatePermission
          ? ('general.forbidden_action' | translate)
          : ''
      "
      data-test-id="wilson-necessary-stay-toggle:is-over-night-stay-required"
    ></nz-switch>
  </div>
</div>

<ng-template #checkedTemplate>
  <i class="fa fa-check icon-mar-top"></i>
</ng-template>
